import React, { useContext } from 'react';
import { Field } from 'formik';
import { SttDatePicker, SttTranslateHook } from '@stt-componentes/core';
import { DATA_LAUDO_ATE } from '../fieldNames';

const DataFinalLaudo = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <Field name={DATA_LAUDO_ATE}>
            {({
                field: { name, value, },
                form: { setFieldValue, setFieldError, setFieldTouched },
                meta
            }) => {
                let erroAtual = meta.error;
                return (
                    <SttDatePicker
                        label={strings.dataFinalLaudo}
                        inputProps={{
                            name: name,
                            inputMode: 'numeric'
                        }}
                        disableFuture
                        maxDate={new Date()}
                        error={meta.touched && meta.error ? true : false}
                        onError={error => {
                            if (error && error !== erroAtual) {
                                setFieldError(DATA_LAUDO_ATE, error);
                            }

                        }}
                        value={value}
                        onBlur={() => {
                            setFieldTouched(DATA_LAUDO_ATE, true);
                        }}
                        helperText={meta.touched && meta.error ? meta.error : undefined}
                        onChange={date => {
                            if (!date) {
                                setFieldValue(DATA_LAUDO_ATE, null, true);
                                return;
                            };

                            date.setHours(0);
                            date.setMinutes(0);
                            date.setSeconds(0);
                            setFieldValue(DATA_LAUDO_ATE, date, true);
                        }}
                        onClose={() => setFieldTouched(DATA_LAUDO_ATE, true)}
                    />
                );
            }}
        </Field>
    );
};

export default DataFinalLaudo;