import TYPES from './types/worklist';

const INITIAL_STATE = {
    filtros: null,
    pesquisaAvancada: false,
    atualizarBusca: false,
};

const worklist = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_FILTROS:
            return {
                ...state,
                filtros: payload.parametros
            }

        case TYPES.SET_PESQUISA_AVANCADA:
            return {
                ...state,
                pesquisaAvancada: payload
            }

        case TYPES.RESET:
            return {
                ...state,
                filtros: payload.parametros
            };

        case TYPES.ATUALIZAR_BUSCA:
            return {
                ...state,
                atualizarBusca: payload.atualizar
            }

        default:
            return state;
    }
}

export default worklist;
