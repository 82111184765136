import * as yup from 'yup'
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import solicitanteSchema from './solicitante/validationSchema';
import geralSchema from './geral/validationSchema';
import pacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';

const schema = (strings, campos) => {
    const paciente = yup.object().shape({ [PACIENTE]: pacienteSchema(strings, campos) });
    const solicitante = solicitanteSchema(strings);
    const geral = geralSchema(strings);

    const schema = yup.object().shape({});

    return schema
        .concat(solicitante)
        .concat(geral)
        .concat(paciente);
}

export default schema;