export const PERFIL = {
	ADMINISTRADOR: 'administrador',
	ADMINISTRADOR_ESTADUAL: 'adminEstadual',
	ADMINISTRADOR_LOCAL: 'adminLocal',
	ADMINISTRADOR_TELEMEDICINA: 'adminTelemedicina',
	ADMINISTRADOR_MUNICIPAL: 'adminMunicipal',
	MEDICO_EXECUTOR: 'medicoExecutor',
	MEDICO_LAUDADOR: 'medicoLaudador',
	MEDICO_RESIDENTE: 'medicoResidente',
	MEDICO_SOLICITANTE: 'medicoSolicitante',
	MEDICO_VISUALIZADOR: 'medicoVisualizador',
	REGULADOR_EXAME: 'reguladorExame',
	TECNICO: 'tecnico',
	VISUALIZADOR_REDE: 'visualizadorRede'
};

export const SIM = 'S';
export const NAO = 'N';

export const VINCULO_PERFIL = {
	GLOBAL: 'G',
	INSTITUICAO: 'I',
	REDE: 'R',
	USUARIO: 'U',
	MUNICIPAL: 'M'
};

export const PERMISSOES = {
	ACESSAR_HISTORICO_EXAMES: 'EXAMES_ACESSAR_HIST_EXAMES',
	ADICIONAR_ANEXO: 'EXAMES_ADICIONAR_ANEXO',
	CADASTRAR_MODELO_LAUDO: 'EXAMES_CAD_MODELO_LAUDO',
	DIRECIONAR_EXAME: 'EXAMES_DIRECIONAR_EXAME',
	DOWNLOAD_EXAME: 'EXAMES_DOWNLOAD_EXAME',
	EXAMES: 'EXAMES_EXAMES',
	EXCLUIR_MODELO_LAUDO: 'EXAMES_EXCLUIR_MODELO_LAUDO',
	HISTORICO_PACIENTE: 'EXAMES_HISTORICO_PACIENTE',
	INSERIR_INDICACAO_CLINICA: 'EXAMES_INSERIR_IND_CLINICA',
	INVALIDAR_EXAME: 'EXAMES_INVALIDAR_EXAME',
	INVALIDAR_EXAME_EMISSAO_LAUDO: 'EXAMES_INV_EXAME_EM_LAUDO',
	LAUDAR_EXAME: 'EXAMES_LAUDAR_EXAME',
	VISUALIZ_INDICACAO_CLINICA: 'EXAMES_VISUALIZ_IND_CLINICA',
	VISUALIZAR_EXAME: 'EXAMES_VISUALIZACAO',
	VISUALIZAR_IMAGEM: 'EXAMES_VISUALIZAR_IMAGEM',
	VISUALIZAR_MODELO_LAUDO: 'EXAMES_VIS_MODELO_LAUDO',
	VISUALIZAR_WORKLIST: 'EXAMES_VISUALIZAR_WORKLIST',
	ENVIAR_EXAME_WORKLIST: 'EXAMES_ENVIA_EXAME_WORKLIST'
};

export const MODALIDADE = {
	DERMATOLOGIA: 'VLP',
	ELETROCARDIOGRAMA: 'ECG',
	ELETROENCEFALOGRAFIA: 'EEG',
	ESPIROMETRIA: 'ESP',
	ESTOMATOLOGIA: 'EST',
	PATOLOGIA: 'BP',
	POLISSONOGRAFIA: 'PSG',
	RADIOLOGIA: 'CR'
};

export const ORIGEM_MANUAL = 'M';

export const TIPO_USUARIO = {
	EXTERNO: 'EXTERNO',
	INTERNO: 'INTERNO'
};

export const TIPO_ANEXO = {
	BMP: 'bmp',
	PDF: 'pdf'
};

export const CONSTANTE_TIPO_BASE_BANCO = 'BD';
export const CONSTANTE_TIPO_BASE_MINIO = 'MINIO';
export const CONSTANTE_TIPO_BASE_PACS = 'PACS';

export const VISUALIDADOR_DICOM = {
	OHIF: 'OHIF',
	OVIYAM: 'OVIYAM'
};

export const ACESSO_WEASIS = {
	DOWNLOAD: 'W',
	INSTALADO: 'L'
};

export const CONSELHO_TRABALHO = {
	CRM: 'CRM'
};

export const MODALIDADE_HISTORICO_EXAME = ['CR'];

export const DESCRICAO_TIPO_MODALIDADE_TELEDIAGNOSTICO = 'Telediagnóstico';

export const MODALIDADES_TELEDIAGNOSTICO = [
	MODALIDADE.ELETROCARDIOGRAMA,
	MODALIDADE.ELETROENCEFALOGRAFIA,
	MODALIDADE.DERMATOLOGIA,
	MODALIDADE.ESPIROMETRIA,
	MODALIDADE.ESTOMATOLOGIA,
	MODALIDADE.POLISSONOGRAFIA
];

export const ASSINATURA_DIGITAL = {
	NEOID: {
		ALGORITMO: {
			SHA256: '2.16.840.1.101.3.4.2.1',
			SHA512: '2.16.840.1.101.3.4.2.3'
		},
		AUTHORIZATION_CODE: 'authorization_code',
		AUTHORIZATION_TOKEN: 'neoid-authorization-token',
		CODE_VERIFIER: 'neoid-code-verifier',
		CONFIG: 'neoid-config',
		ESCOPO: {
			MULTI_SIGNATURE: 'multi_signature',
			SIGNATURE_SESSION: 'signature_session',
			SINGLE_SIGNATURE: 'single_signature'
		},
		FORMATO: {
			CMS: 'CMS',
			RAW: 'RAW'
		},
		STATE: 'stt-app-state'
	}
};

export const SITUACAO_LAUDO = {
	AGUARDANDO_ASSINATURA_DIGITAL: 'G',
	AGUARDANDO_COMPLEMENTO: 'A',
	COM_LAUDO: 'S',
	COM_LAUDO_APLICATIVO: 'M',
	EM_EMISSAO: 'E',
	SEM_LAUDO: 'N',
	TEMPORARIO: 'T'
};

export const TIPO_ABA_EXAME = {
	INSTITUICAO: 'INSTITUICAO',
	REDE: 'REDE',
	PERFIL: 'PERFIL'
};

export const LAUDO_EM_EMISSAO = {
	SIM: 'S',
	NAO: 'N'
};

export const SITUACAO = {
	VALIDO: 'V',
	INVALIDO: 'I'
};

export const ORIGEM = {
	PACS: 'D',
	AGENDADOS: 'A',
	TELEDIAGNOSTICO: 'M'
};

export const TIPO_BUSCA = {
	TODOS_TERMOS: 'T',
	QUALQUER_TERMO: 'Q'
};

export const PAGINACAO = {
	REGISTROS_PAGINA: 'REGISTROS_PAGINA'
};
