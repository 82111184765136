import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AbaSolicitacao from '../../componentes/worklist/solicitacao';
import { SttTabs } from '@stt-componentes/core';

const Solicitacao = () => {
    const location = useLocation();
    const [abas, setAbas] = useState([]);
    
    useEffect(() => {
        setAbas([{
            titulo: 'solicitação',
            conteudo: AbaSolicitacao,
            key: 'AbaSolicitacao',
            permanente: true,
            // id: id
        }]);
    }, [location]);

    return (
        <SttTabs 
            abas={abas}
            abaAtiva="0"
            permanente={true}
            preload={false}
        />
    );

}

export default Solicitacao;