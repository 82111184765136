import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setUrlOhif } from '../../reducers/actions/exame';
import axios from 'axios';
import { getHeaders } from '../../request';
import SttGaleriaImagens from '@stt-componentes/galeria-telediagnostico';
import { temPermissaoRBAC, temPerfilRBAC } from 'src/secutity/acl';
import { PERFIL, PERMISSOES, MODALIDADE, MODALIDADES_TELEDIAGNOSTICO, SIM } from 'src/common/Constants';
import { SttNotification, SttTranslateHook, SttCircularProgress, SttGrid, SttText, SttButton } from '@stt-componentes/core';
import ListaAnexos from 'src/componentes/exame/listaAnexos';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const useStyles = makeStyles((theme) => ({
	divListaImagens: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	notificationContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	notification: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	divLoading: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'end'
	},
	iconButton: {
		padding: 0
	}
}));

const Viewer = (props) => {
	const { user, exame, anexos, token, urlOhif, setUrlOhif, fecharFn, destacarFn, botoes = false } = props;
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const classes = useStyles();
	const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;
	const [permissaoVerImagens, setPermissaoVerImagens] = useState(true);
	const [loadOhif, setLoadOhif] = useState(false);
	const dicomCache = global.gConfig.dicom_cache_ativo;

	useEffect(() => {
		if (!urlOhif[exame.id]) {
			if (
				!temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_IMAGEM) ||
				(temPerfilRBAC(user, PERFIL.TECNICO) &&
					(exame.sigla_modalidade === MODALIDADE.DERMATOLOGIA || exame.sigla_modalidade === MODALIDADE.ESTOMATOLOGIA))
			) {
				setPermissaoVerImagens(false);
			} else {
				if (dicomCache) {
					axios
						.get(`${EXAMES_API_BASE_URL}/exame/${exame.studyinstanceuid}/dicom-cache`, { headers: getHeaders() })
						.then((response) => {
							const { data } = response;
							setUrlOhif(exame.id, data.data.url_ohif);
						})
						.catch((err) => {
							console.log(err);
							setUrlOhif(exame.id, exame.url_ohif);
						});
				} else {
					setUrlOhif(exame.id, exame.url_ohif);
				}
			}
		}
	}, []);

	useEffect(() => {
		if (urlOhif[exame.id]) {
			let url = urlOhif[exame.id].replace('<StudyInstanceUID>', exame.studyinstanceuid);
			if (exame.versao_dcm4chee === 5) {
				setUrlOhif(exame.id, url.replace('<token>', token));
			} else {
				setUrlOhif(exame.id, url.replace('?access_token=<token>', ''));
			}
			setLoadOhif(true);
		}
	}, [urlOhif[exame.id]]);

	return (
		<>
			{!permissaoVerImagens ? (
				<SttNotification
					severity="info"
					className={classes.notification}
				>
					<div className={classes.notificationContent}>
						<span>{strings.exameSemPermissaoGaleria}</span>
					</div>
				</SttNotification>
			) : (
				<>
					{!MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade) && exame.possui_imagem !== SIM ? (
						<SttNotification
							severity="info"
							className={classes.notification}
						>
							<div className={classes.notificationContent}>
								<span>{strings.exameSemImagens}</span>
							</div>
						</SttNotification>
					) : (
						<>
							{!MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade) && exame.possui_imagem === SIM && (
								<>
									{!loadOhif ? (
										<SttGrid
											container
											spacing={3}
										>
											<SttGrid
												item
												xs={12}
												className={classes.divLoading}
											>
												<SttCircularProgress />
												<SttText>{`${strings.carregandoImagens}...`}</SttText>
											</SttGrid>
										</SttGrid>
									) : (
										<div>
											{botoes && (
												<div className={classes.buttonsWrapper}>
													<IconButton
														color="primary"
														onClick={destacarFn}
														className={classes.iconButton}
														disableRipple
													>
														<FullscreenIcon />
													</IconButton>
													<IconButton
														color="primary"
														onClick={() => fecharFn(true)}
														className={classes.iconButton}
														disableRipple
													>
														<CloseIcon />
													</IconButton>
												</div>
											)}
											<iframe
												width="100%"
												height={window.innerHeight * 0.78}
												src={urlOhif[exame.id]}
												style={{ border: 'none' }}
											/>
										</div>
									)}
								</>
							)}
							{MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade) && (
								<SttGaleriaImagens
									idExame={exame.id}
									strings={strings}
									config={global.gConfig}
									tokenTelessaude={token}
								/>
							)}
						</>
					)}
				</>
			)}

			{anexos?.length > 0 && (
				<div className={classes.divListaImagens}>
					<ListaAnexos anexos={anexos} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user,
		urlOhif: state.exame.urlOhif
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUrlOhif: (id, url) => dispatch(setUrlOhif({ id, url }))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
