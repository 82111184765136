import * as yup from 'yup'
import { DATA, DESCRICAO, GERAL, REQUISICAO } from './fieldNames'

const schema = (strings) => {
    return yup.object().shape({
        [GERAL]: yup.object().shape({
            [DATA]: yup
                .date()
                .typeError(strings.dataInvalida)
                .min(new Date(), strings.erroDataPassada)
                .nullable()
                .required(strings.campoObrigatorio),
            [DESCRICAO]: yup
                .array()
                .of(
                    yup
                        .string()
                        .trim()
                        .nullable()
                        .required(strings.campoObrigatorio)
                )
                .required(strings.campoObrigatorio),
            [REQUISICAO]: yup
                .string()
                .trim()
                .nullable()
                .matches(/^[a-zA-Z0-9]+$/, strings.erroRequisicao)
                .required(strings.campoObrigatorio),

        }).required()
    });
}

export default schema;