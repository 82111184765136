import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttGrid,
    SttTranslateHook,
    SttCardHeader,
    SttCardContent,
    SttCard,
} from '@stt-componentes/core';
import DataInicialExame from './campos/data-inicial-exame';
import DataFinalExame from './campos/data-final-exame';
import DataInicialLaudo from './campos/data-inicial-laudo';
import DataFinalLaudo from './campos/data-final-laudo';
import SituacaoLaudo from './campos/situacao-laudo';
import SituacaoExame from './campos/situacao-exame';
import Origem from './campos/origem';
import Uf from './campos/uf';
import Municipio from './campos/municipio';
import Instituicao from './campos/instituicao';
import Rede from './campos/rede';
import Requisicao from './campos/requisicao';
import Protocolo from './campos/protocolo';
import NumeroExame from './campos/numero-exame';
import Modalidade from './campos/modalidade';
import ResponsavelEnvio from './campos/responsavel-envio';
import DescricaoExame from './campos/descricao-exame';
import ExameValidado from './campos/exame-validado';
import ExamePriorizado from './campos/exame-priorizado';
import Paciente from './campos/paciente';
import ComImagem from './campos/com-imagem';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%'
    }
}));

const FormGeral = (props) => {
    const { filtroPorRede, filtroPorFuncionario, filtroPorDescricao, filtroValidado, filtroPriorizado } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <>
            <SttGrid container spacing={2}>
                <SttGrid item xs={12}>
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardHeader subheader={strings.data} />
                        <SttCardContent>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <DataInicialExame />
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <DataFinalExame />
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <DataInicialLaudo />
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <DataFinalLaudo />
                                </SttGrid>
                            </SttGrid>
                        </SttCardContent>
                    </SttCard>
                </SttGrid>
                <SttGrid item xs={12} sm={12} md={8}>
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardHeader subheader={strings.situacao} />
                        <SttCardContent>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={12} md={9} lg={9}>
                                    <SituacaoLaudo />
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={3} lg={3}>
                                    <SituacaoExame />
                                </SttGrid>
                            </SttGrid>
                        </SttCardContent>
                    </SttCard>
                </SttGrid>
                <SttGrid item xs={12} sm={12} md={4}>
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardHeader subheader={strings.origem} />
                        <SttCardContent>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12}>
                                    <Origem />
                                </SttGrid>
                            </SttGrid>
                        </SttCardContent>
                    </SttCard>
                </SttGrid>
                <SttGrid item xs={12} sm={12}>
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardHeader subheader={strings.localizacao} />
                        <SttCardContent>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={3} md={3} lg={1}>
                                    <Uf />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={4} lg={3}>
                                    <Municipio />
                                </SttGrid>
                                <SttGrid item xs={12} sm={5} md={5} lg={4}>
                                    <Instituicao />
                                </SttGrid>
                                {
                                    filtroPorRede &&
                                    <SttGrid item xs={12} sm={6} md={6} lg={2}>
                                        <Rede />
                                    </SttGrid>
                                }
                            </SttGrid>
                        </SttCardContent>
                    </SttCard>
                </SttGrid>
                <SttGrid item xs={12} sm={12} md={12}>
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardHeader subheader={strings.exame} />
                        <SttCardContent>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <Requisicao />
                                </SttGrid>

                                <SttGrid item xs={12} sm={4} md={2}>
                                    <Protocolo />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <NumeroExame />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <Modalidade />
                                </SttGrid>
                                {
                                    filtroPorFuncionario &&
                                    <SttGrid item xs={12} sm={4} md={2}>
                                        <ResponsavelEnvio />
                                    </SttGrid>
                                }
                                {
                                    filtroPorDescricao &&
                                    <>
                                        <SttGrid item xs={12} sm={4} md={2}>
                                            <DescricaoExame />
                                        </SttGrid>
                                    </>
                                }
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <ComImagem />
                                </SttGrid>
                            </SttGrid>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12}>
                                    {
                                        filtroValidado &&
                                        <ExameValidado />
                                    }
                                    {
                                        filtroPriorizado &&
                                        <ExamePriorizado />
                                    }
                                </SttGrid>
                            </SttGrid>
                        </SttCardContent>
                    </SttCard>
                </SttGrid>

                <SttGrid item xs={12} sm={12} md={6}>
                    <Paciente />
                </SttGrid>
            </SttGrid>
        </>
    );
};

export default FormGeral;