import TYPES from '../types/worklist'

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SET_FILTROS,
        payload: { parametros }
    };
}

export const setPesquisaAvancada = (avancada) => {    
    return {
        type: TYPES.SET_PESQUISA_AVANCADA,
        payload: avancada
    }
}

export const reset = (parametros) => {
    return {
        type: TYPES.RESET,
        payload: { parametros }
    };
}

export const setAtualizarBusca = (atualizar) => {
    return {
        type: TYPES.ATUALIZAR_BUSCA,
        payload: { atualizar: atualizar }
    };
}