import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SttTabs } from '@stt-componentes/core';
import { PERFIL, TIPO_ABA_EXAME, VINCULO_PERFIL } from 'src/common/Constants';

import Aba from '../../componentes/produtividade';

const Produtividade = (props) => {
	const { user } = props;
	const [abas, setAbas] = useState([]);
	const [abaAtiva, setAbaAtiva] = useState('0');

	const location = useLocation();

	useEffect(() => {
		let dadosAbas = [];
		if (user.perfisRBAC) {
			const perfisInteresse = user.perfisRBAC.filter((p) => [PERFIL.MEDICO_EXECUTOR].includes(p.identificador));
			perfisInteresse.forEach((perfil) => {
				// INSTITUIÇÃO
				if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
					perfil.instituicoes.forEach((instituicao) => {
						dadosAbas.push({
							id: instituicao.id,
							tipo: TIPO_ABA_EXAME.INSTITUICAO,
							titulo: instituicao.nome,
							conteudo: Aba,
							permanente: true
						});
					});
				}
			});
			setAbas(dadosAbas);
		}
	}, [user]);

	useEffect(() => {
		if (location.state?.aba) {
			setAbaAtiva('' + location.state.aba);
		}
	}, [location]);

	return (
		<SttTabs
			abas={abas}
			abaAtiva={abaAtiva}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user
	};
};

export default connect(mapStateToProps)(Produtividade);
