import Cookies from 'js-cookie';
import { PERFIL, PAGINACAO, ORIGEM } from 'src/common/Constants';
import { temPerfilRBAC } from '../../../secutity/acl';

export const estadoInicialForm = (config, user) => {
	let dataDe = new Date();
	let dataAte = new Date();
	dataDe.setDate(dataAte.getDate() - 30);

	const origem = [ORIGEM.PACS, ORIGEM.AGENDADOS];
	if (!temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR)) {
		origem.push(ORIGEM.TELEDIAGNOSTICO);
	}

	return {
		uf: null,
		municipio: null,
		instituicao: null,
		categoria: null,
		rede: null,
		modalidade: null,
		'funcionario-envio': '',
		requisicao: '',
		paciente: '',
		'cns-paciente': '',
		'cpf-paciente': '',
		'data-exame-de': null,
		'data-exame-ate': dataAte,
		'data-laudo-de': null,
		'data-laudo-ate': null,
		'situacao-laudo': ['N', 'S', 'T', 'E', 'A', 'AC', 'G'],
		atrasado: false,
		imagens: false,
		'ultrapassou-tempo-previsto': false,
		situacao: ['V'],
		origem: origem,
		protocolo: '',
		'numero-exame': '',
		'descricao-exame': '',
		'tipo-busca-descricao': 'T',
		direcionados: false,
		'exame-validado': false,
		'exame-priorizado': false,
		'responsavel-laudo': false,
		page: config.pagination.start,
		count: Cookies.get(PAGINACAO.REGISTROS_PAGINA) || config.pagination.count
	};
};
