import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttGrid,
    SttTranslateHook,
    SttCardHeader,
    SttCardContent,
    SttCard
} from '@stt-componentes/core';
import DataInicialExame from './campos/data-inicial-exame';
import DataFinalExame from './campos/data-final-exame';
import Uf from './campos/uf';
import Municipio from './campos/municipio';
import Instituicao from './campos/instituicao';
import Requisicao from './campos/requisicao';
import Protocolo from './campos/protocolo';
import Modalidade from './campos/modalidade';
import ResponsavelEnvio from './campos/responsavel-envio';
import DescricaoExame from './campos/descricao-exame';
import Paciente from './campos/paciente';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%'
    }
}));

const FormGeral = (props) => {
    const { filtroPorFuncionario, filtroPorDescricao } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <SttGrid container spacing={1}>
            <SttGrid item xs={12} sm={12} md={4}>
                <SttCard variant="outlined" className={classes.card}>
                    <SttCardHeader subheader={strings.data} />
                    <SttCardContent>
                        <SttGrid container spacing={1}>
                            <SttGrid item xs={12} sm={12} md={6}>
                                <DataInicialExame />
                            </SttGrid>
                            <SttGrid item xs={12} sm={12} md={6}>
                                <DataFinalExame />
                            </SttGrid>
                        </SttGrid>
                    </SttCardContent>
                </SttCard>
            </SttGrid>
            <SttGrid item xs={12} sm={12} md={8}>
                <SttCard variant="outlined" className={classes.card}>
                    <SttCardHeader subheader={strings.localizacao} />
                    <SttCardContent>
                        <SttGrid container spacing={1}>
                            <SttGrid item xs={12} sm={3} md={3} lg={1}>
                                <Uf />
                            </SttGrid>
                            <SttGrid item xs={12} sm={4} md={4} lg={5}>
                                <Municipio />
                            </SttGrid>
                            <SttGrid item xs={12} sm={5} md={5} lg={6}>
                                <Instituicao />
                            </SttGrid>
                        </SttGrid>
                    </SttCardContent>
                </SttCard>
            </SttGrid>
            <SttGrid item xs={12} sm={12} md={6} >
                <SttCard variant="outlined" className={classes.card}>
                    <SttCardHeader subheader={strings.exame} />
                    <SttCardContent>
                        <SttGrid container spacing={1}>
                            <SttGrid item xs={12} sm>
                                <Requisicao />
                            </SttGrid>
                            <SttGrid item xs={12} sm>
                                <Protocolo />
                            </SttGrid>
                            <SttGrid item xs={12} sm>
                                <Modalidade />
                            </SttGrid>
                            {
                                filtroPorFuncionario &&
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <ResponsavelEnvio />
                                </SttGrid>
                            }
                            {
                                filtroPorDescricao &&
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <DescricaoExame />
                                </SttGrid>
                            }
                        </SttGrid>
                    </SttCardContent>
                </SttCard>
            </SttGrid>
            <SttGrid item xs={12} sm={12} md={6}>
                <Paciente />
            </SttGrid>
        </SttGrid>
    );
};

export default FormGeral;