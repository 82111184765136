import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import { PERMISSOES, MODALIDADES_TELEDIAGNOSTICO, SITUACAO_LAUDO } from 'src/common/Constants';
import { temPermissaoRBAC } from 'src/secutity/acl';
import { SttButton, SttGrid, SttInput, SttModal, SttAlerta, SttLoading, SttTranslateHook } from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%'
	}
}));

const IndicacaoClinica = (props) => {
	const { user, exame, callback } = props;
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const classes = useStyles();

	const [indicacao, setIndicacao] = useState(exame.indicacao_clinica || '');
	const [id] = useState(exame.id_indicacao_clinica || null);
	const [salvando, setSalvando] = useState(false);
	const [podeAddIndicacaoClinica, setPodeAddIndicacaoClinica] = useState(false);
	// Alerta
	const [mostrarAlerta, setMostrarAlerta] = useState(false);
	const [tituloAlerta, setTituloAlerta] = useState('');
	const [tipoAlerta, setTipoAlerta] = useState('alert');
	const [mensagemAlerta, setMensagemAlerta] = useState('');
	const [opcoesAlerta, setOpcoesAlerta] = useState([]);
	const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

	useEffect(() => {
		if (
			temPermissaoRBAC(user, PERMISSOES.INSERIR_INDICACAO_CLINICA) &&
			[
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.TEMPORARIO,
				SITUACAO_LAUDO.AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.AGUARDANDO_ASSINATURA_DIGITAL
			].includes(exame.situacao_laudo) &&
			!MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade)
		) {
			setPodeAddIndicacaoClinica(true);
		}
	}, []);

	const handleChangeIndicacao = (event) => {
		setIndicacao(event.target.value);
	};

	const salvar = () => {
		setSalvando(true);
		axios
			.put(`${EXAME_API_BASE_URL}/exame/${exame.id}/indicacao-clinica`, { id, indicacao }, { headers: getHeaders() })
			.then((resposta) => {
				exame.id_indicacao_clinica = resposta.data.id;
				exame.indicacao_clinica = resposta.data.indicacao;
				setTipoAlerta('success');
				setTituloAlerta(strings.sucesso);
				setMensagemAlerta(strings.indicacaoClinicaRegistrada);
				setMostrarAlerta(true);
				setOpcoesAlerta([
					{
						title: strings.ok,
						onClick: () => {
							setMostrarAlerta(false);
							fechar();
						}
					}
				]);
			})
			.catch((err) => {
				console.log(err);
				const { response } = err;
				let mensagemAlerta = strings.erroGenerico;
				if (response) {
					let msg = '';
					if (response.status === HttpStatus.BAD_REQUEST) {
						const dadosResposta = response.data;
						let arrMensagem = [];
						dadosResposta.errors.forEach((error) => {
							arrMensagem.push(`- ${error.message}`);
						});
						msg = arrMensagem.join('\n');
						mensagemAlerta = msg;
					}
				}
				setTipoAlerta('error');
				setTituloAlerta(strings.erro);
				setMensagemAlerta(mensagemAlerta);
				setOpcoesAlerta([{ title: strings.ok, onClick: () => setMostrarAlerta(false) }]);
				setMostrarAlerta(true);
			})
			.finally(() => setSalvando(false));
	};

	const fechar = () => {
		setIndicacao('');
		callback();
	};

	return (
		<SttModal
			title={strings.indicacaoClinica}
			open={true}
			outModalClose={fechar}
			iconClose={fechar}
			maxWidth="sm"
			fullWidth={true}
			children={
				<SttGrid
					container
					spacing={3}
				>
					<SttGrid
						item
						xs={12}
					>
						<SttInput
							multiline
							minRows={4}
							required
							disabled={!podeAddIndicacaoClinica}
							value={indicacao}
							label={strings.indicacaoClinica}
							onChange={handleChangeIndicacao}
						/>
					</SttGrid>
					<SttAlerta
						open={mostrarAlerta}
						title={tituloAlerta}
						message={mensagemAlerta}
						type={tipoAlerta}
						options={opcoesAlerta}
						onClose={() => setMostrarAlerta(false)}
					/>
					<div className={classes.carregando}>
						<SttLoading
							open={salvando}
							text={strings.aguarde}
						/>
					</div>
				</SttGrid>
			}
			footer={
				<div>
					{podeAddIndicacaoClinica && (
						<SttButton
							variant="contained"
							color="primary"
							onClick={salvar}
							disabled={!indicacao.trim()}
						>
							{strings.salvar}
						</SttButton>
					)}
					<SttButton
						variant="outlined"
						color="primary"
						onClick={fechar}
					>
						{strings.fechar}
					</SttButton>
				</div>
			}
		/>
	);
};

IndicacaoClinica.propTypes = {
	exame: PropTypes.object.isRequired,
	callback: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return { user: state.index.user };
};

export default connect(mapStateToProps)(IndicacaoClinica);
