import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import { setScope } from '../../reducers/actions/assinatura-digital';
import { useHistory } from 'react-router-dom';
import HttpStatus from 'http-status-codes';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import Neoid from '../assinatira-digital/neoid';
import { ASSINATURA_DIGITAL, SITUACAO_LAUDO } from 'src/common/Constants';
import { NotificationManager } from 'react-notifications';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import {
	SttButton,
	SttLoading,
	SttHeading,
	SttModal,
	SttFormControl,
	SttRadioGroup,
	SttFormControlLabel,
	SttRadioButton,
	SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
	radioLabel: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline'
	}
}));

const Publicacao = (props) => {
	const { assinaturaDigital, prePublicar, setAtualizarBusca, escopoAssinaturaDigital, setScope } = props;
	const classes = useStyles();

	const { strings } = useContext(SttTranslateHook.I18nContext);
	const { values } = useFormikContext();
	const history = useHistory();

	const [neoIDHabilitado, setNeoIDHabilitado] = useState();
	const [confirmando, setConfirmando] = useState(false);
	const [alerta, setAlerta] = useState(false);
	const [opcaoAssinatura, setOpcaoAssinatura] = useState(escopoAssinaturaDigital);
	const [laudos, setLaudos] = useState(null);
	const [assinar, setAssinar] = useState(false);

	useEffect(() => {
		setNeoIDHabilitado(assinaturaDigital?.neoid?.ativo);
	}, []);

	const publicar = () => {
		// Garante que o componente de assinatura seja removido do DOM
		// fazendo com que o processo de assinatura seja iniciado sem erros
		setAssinar(false);
		setConfirmando(true);
		const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
		axios
			.post(`${EXAME_API_BASE_URL}/laudo`, values, { headers: getHeaders() })
			.then((response) => {
				const { data } = response;
				posPublicar([data.data.laudo], data.data.situacao);
			})
			.catch((err) => {
				console.log(err);
				const { response } = err;
				let msg = strings.erroGenerico;
				if (response) {
					if (response.status === HttpStatus.BAD_REQUEST) {
						const { data } = response;
						let arrMensagem = [];
						data.errors.forEach((error) => {
							arrMensagem.push(`- ${error.message}`);
						});
						msg = arrMensagem.join('\n');
					}
				}
				NotificationManager.error(msg);
			})
			.finally(() => {
				setConfirmando(false);
			});
	};

	const posPublicar = (laudos, situacao) => {
		NotificationManager.success(strings.laudoRegistrado);
		// Somente inicializa o processo de assinatura quando as duas condiçòes abaixo forem atendidas:
		//  - O neoID está habilitado
		//  - O laudo é definitivo
		if (situacao === SITUACAO_LAUDO.COM_LAUDO || situacao === SITUACAO_LAUDO.AGUARDANDO_ASSINATURA_DIGITAL) {
			if (neoIDHabilitado) {
				inicializarFluxoNeoId(laudos);
			} else {
				voltar();
			}
		} else {
			voltar();
		}
	};

	const inicializarFluxoNeoId = (laudos) => {
		setLaudos(laudos);
		if (opcaoAssinatura === ASSINATURA_DIGITAL.NEOID.ESCOPO.MULTI_SIGNATURE) {
			// Finaliza a emissão sem assinar o laudo. Será criada uma lista de laudos aguardando assinatura.
			voltar();
		} else if (opcaoAssinatura === ASSINATURA_DIGITAL.NEOID.ESCOPO.SIGNATURE_SESSION) {
			// Inicializa o processo de assinatura automaticamente
			// - o usuário tem uma sessão de assinatura ativa.
			setAssinar(true);
		} else {
			// Solicita o tipo de assinatura a cada laudo emitido
			setAlerta(true);
		}
	};

	const voltar = () => {
		history.goBack();
		setAtualizarBusca(true);
	};

	const handleChangeOpcoesAssinatura = (e) => {
		setOpcaoAssinatura(e.target.value);
	};

	const confirmarOpcaoAssinatura = () => {
		setAlerta(false);
		setScope(opcaoAssinatura);
		if (opcaoAssinatura === ASSINATURA_DIGITAL.NEOID.ESCOPO.MULTI_SIGNATURE) {
			voltar();
			return;
		}
		setAssinar(true);
	};

	return (
		<>
			<SttButton
				variant="contained"
				color="primary"
				disabled={confirmando}
				onClick={() => {
					if (prePublicar) {
						prePublicar().then(() => publicar());
					} else {
						publicar();
					}
				}}
				nomarginleft="true"
			>
				{strings.publicar}
			</SttButton>
			{assinar && (
				<Neoid
					laudos={laudos}
					escopo={opcaoAssinatura}
					callback={voltar}
				/>
			)}
			<SttLoading
				open={confirmando}
				text={strings.confirmando}
			/>

			<SttModal
				title={strings.assinaturaDigital}
				open={alerta}
				maxWidth="sm"
				fullWidth={true}
				children={
					<>
						<SttHeading variant="h4">{strings.assinaturaNecessaria}</SttHeading>
						<SttFormControl
							variant="outlined"
							onChange={handleChangeOpcoesAssinatura}
						>
							<SttRadioGroup>
								<div className={classes.radioLabel}>
									<SttFormControlLabel
										control={
											<SttRadioButton
												type="radio"
												value={ASSINATURA_DIGITAL.NEOID.ESCOPO.SINGLE_SIGNATURE}
												color="primary"
												checked={opcaoAssinatura === ASSINATURA_DIGITAL.NEOID.ESCOPO.SINGLE_SIGNATURE}
											/>
										}
										label={strings.assinarEsteLaudoApenas}
									/>
								</div>
								<div className={classes.radioLabel}>
									<SttFormControlLabel
										control={
											<SttRadioButton
												type="radio"
												value={ASSINATURA_DIGITAL.NEOID.ESCOPO.SIGNATURE_SESSION}
												color="primary"
												checked={opcaoAssinatura === ASSINATURA_DIGITAL.NEOID.ESCOPO.SIGNATURE_SESSION}
											/>
										}
										label={strings.assinarEsteEProximosLaudos}
									/>
									<Tooltip
										arrow
										title={strings.textoAjudaSessaoAssinatura}
										placement="right"
									>
										<HelpOutlineIcon />
									</Tooltip>
								</div>
								<div className={classes.radioLabel}>
									<SttFormControlLabel
										control={
											<SttRadioButton
												type="radio"
												value={ASSINATURA_DIGITAL.NEOID.ESCOPO.MULTI_SIGNATURE}
												color="primary"
												checked={opcaoAssinatura === ASSINATURA_DIGITAL.NEOID.ESCOPO.MULTI_SIGNATURE}
											/>
										}
										label={strings.assinarDepois}
									/>
									<Tooltip
										arrow
										title={strings.textoAjudaAssinarDepois}
										placement="right"
									>
										<HelpOutlineIcon />
									</Tooltip>
								</div>
							</SttRadioGroup>
						</SttFormControl>
					</>
				}
				footer={
					<div>
						<SttButton
							variant="contained"
							color="primary"
							onClick={confirmarOpcaoAssinatura}
							disabled={!opcaoAssinatura}
						>
							{strings.confirmar}
						</SttButton>
					</div>
				}
			/>
		</>
	);
};

Publicacao.propTypes = {
	callback: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		assinaturaDigital: state.assinaturaDigital.config,
		escopoAssinaturaDigital: state.assinaturaDigital.escopo,
		user: state.index.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAtualizarBusca: (atualizar) => dispatch(setAtualizarBuscaAction(atualizar)),
		setScope: (escopo) => dispatch(setScope(escopo))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Publicacao);
