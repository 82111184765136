import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Store } from './store';
import './styles.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import Home from './paginas';
import { SttTranslateHook, SttThemeProvider } from '@stt-componentes/core';

class App extends Component {
	render() {
		return (
			<div>
				<NotificationContainer />
				<Provider store={Store}>
					<SttTranslateHook.I18nContextProvider modulo={'exames'}>
						<SttThemeProvider config={global.gConfig}>
							<Home />
						</SttThemeProvider>
					</SttTranslateHook.I18nContextProvider>
				</Provider>
			</div>
		);
	}

	getChildContext() {
		return {
			currentLanguage: this.props.currentLanguage,
			modulo: this.props.modulo
		};
	}
}

App.childContextTypes = {
	currentLanguage: PropTypes.string.isRequired,
	modulo: PropTypes.string.isRequired
};

export default App;
