import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import { setUrlOhif } from '../../reducers/actions/exame';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import ImageViewer from '../image-viewer';
import { temPermissaoRBAC } from '../../secutity/acl';
import { PERMISSOES, MODALIDADES_TELEDIAGNOSTICO, SIM, DESCRICAO_TIPO_MODALIDADE_TELEDIAGNOSTICO, SITUACAO_LAUDO } from 'src/common/Constants';
import IndicacaoClinica from './indicacaoClinica';
import Direcionamento from './direcionamento';
import Anexo from './anexo';
import Oviyam from '../image-viewer/oviyam';
import Weasis from '../image-viewer/weasis';
import Download from '../exame/download';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import Utils from '../../utils';
import {
	SttButton,
	SttExpansionPanel,
	SttTextItem,
	SttHeading,
	SttContainer,
	SttAlerta,
	SttGrid,
	SttLoading,
	SttNotification,
	SttAlertTitle,
	SttTranslateHook
} from '@stt-componentes/core';
import Invalidar from './invalidar';
import ModalComplementoPaciente from './complemento';
import ModalComplementoSolicitante from './complemento/solicitante';

const useStyles = makeStyles((theme) => ({
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		gap: theme.spacing(2),
		margin: theme.spacing(2)
	},
	notificationContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	notification: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	protocolo: {
		display: 'flex',
		gap: theme.spacing(2),
		alignItems: 'center'
	},
	fonteProtocolo: {
		fontFamily: '"Noto Sans"'
	},
	fileCopyIcon: {
		fontSize: '1.2em',
		cursor: 'pointer',
		color: '#0A6FB8'
	}
}));

const Visualizacao = (props) => {
	const { user, id, setAtualizarBusca, contextoLaudo, copiarLaudo, setUrlOhif, assinaturaDigital } = props;
	const { strings } = useContext(SttTranslateHook.I18nContext);

	const classes = useStyles();
	const history = useHistory();

	const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

	const [exame, setExame] = useState(null);
	const [token, setToken] = useState(getHeaders());
	const [anexos, setAnexos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [podeVisualizarIndicacaoClinica, setPodeVisualizarIndicacaoClinica] = useState(false);
	const [podeAddAnexo, setPodeAddAnexo] = useState(false);
	const [podePriorizar, setPodePriorizar] = useState(false);
	const [podeDirecionar, setPodeDirecionar] = useState(false);
	const [direcionarExame, setDirecionarExame] = useState(false);
	const [adicionarIndicacaoClinica, setAdicionarIndicacaoClinica] = useState(false);
	const [adicionarAnexo, setAdicionarAnexo] = useState(false);
	const [textoTooltip, setTextoTooltip] = useState(strings.copiar);
	const [invalidar, setInvalidar] = useState(false);
	const [gerandoPdf, setGerandoPdf] = useState(false);

	const [exameModalPaciente, setExameModalPaciente] = useState(false);
	const [exameModalSolicitante, setExameModalSolicitante] = useState(false);

	// Alerta
	const [alerta, setAlerta] = useState(false);
	const [mensagemAlerta, setMensagemAlerta] = useState('');
	const [tipoAlerta, setTipoAlerta] = useState('success');
	const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
	const [opcoesAlerta, setOpcoesAlerta] = useState([]);

	const voltar = () => {
		history.goBack();
	};

	useEffect(() => {
		carregarExame();
		carregarAnexos();

		return () => {
			setUrlOhif(id, null);
			if (setAtualizarBusca) {
				setAtualizarBusca(true);
			}
		};
	}, []);

	const carregarExame = (exibirModal = true) => {
		axios
			.get(`${EXAMES_API_BASE_URL}/exame/${id}`, { headers: getHeaders() })
			.then((response) => {
				if (response.data) {
					const { data } = response.data;
					setExame(data);
					if (data.token_pacs && !MODALIDADES_TELEDIAGNOSTICO.includes(data.sigla_modalidade)) {
						setToken(data.token_pacs);
					}

					if (exibirModal) {
						if (
							assinaturaDigital?.neoid?.ativo &&
							[
								SITUACAO_LAUDO.COM_LAUDO,
								SITUACAO_LAUDO.LAUDO_EMISSAO,
								SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
								SITUACAO_LAUDO.LAUDO_TEMPORARIO
							].includes(data.situacao_laudo)
						) {
							return;
						}
						if ((!data.cpf && !data.cpf_paciente) || (!data.data_nascimento_paciente && !data.data_nascimento)) {
							setExameModalPaciente(data);
						} else if (!data.nome_medico_solicitante) {
							setExameModalSolicitante(data);
						}
					}
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setTipoAlerta('error');
				setTituloAlerta(strings.erro);
				setMensagemAlerta(strings.erroCarregarExame);
				setOpcoesAlerta([
					{
						title: strings.ok,
						onClick: () => {
							setAlerta(false);
							voltar();
						}
					}
				]);
				setAlerta(true);
			})
			.finally(() => setLoading(false));
	};

	const carregarAnexos = () => {
		// Carega lista de arquivos anexos ao exame
		axios
			.get(`${EXAMES_API_BASE_URL}/exame/${id}/anexo`, { headers: getHeaders() })
			.then((response) => {
				const { data } = response;
				setAnexos(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (user && exame) {
			if (
				temPermissaoRBAC(user, PERMISSOES.VISUALIZ_INDICACAO_CLINICA) &&
				[
					SITUACAO_LAUDO.SEM_LAUDO,
					SITUACAO_LAUDO.LAUDO_TEMPORARIO,
					SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
					SITUACAO_LAUDO.COM_LAUDO
				].includes(exame.situacao_laudo) &&
				!MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade)
			) {
				setPodeVisualizarIndicacaoClinica(true);
			}
			if (
				temPermissaoRBAC(user, PERMISSOES.ADICIONAR_ANEXO) &&
				[SITUACAO_LAUDO.SEM_LAUDO, SITUACAO_LAUDO.LAUDO_TEMPORARIO, SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO].includes(
					exame.situacao_laudo
				) &&
				!MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade)
			) {
				setPodeAddAnexo(true);
			}

			if (
				temPermissaoRBAC(user, PERMISSOES.DIRECIONAR_EXAME) &&
				[SITUACAO_LAUDO.SEM_LAUDO].includes(exame.situacao_laudo) &&
				!MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade)
			) {
				if (exame.indicacao_clinica || anexos.length > 0) {
					setPodeDirecionar(true);
					setPodePriorizar(true);
				} else {
					setPodeDirecionar(false);
					setPodePriorizar(false);
				}
			}
		}
	}, [user, exame, anexos]);

	const abrirExameModuloOrigem = () => {
		const urlModuloOrigem = global.gConfig.urls_modulos[exame.sigla_modalidade];
		const idBase64 = Buffer.from(exame.id.toString()).toString('base64');
		window.open(`${urlModuloOrigem}/exames/visualizar/${idBase64}`, '_blank');
	};

	const priorizarExame = (motivo) => {
		setLoadingMessage(strings.priorizandoExame);
		setLoading(true);

		const dados = {
			exame: exame.id,
			motivo: motivo.id
		};
		axios
			.post(`${EXAMES_API_BASE_URL}/priorizar`, dados, { headers: getHeaders() })
			.then((resposta) => {
				setLoading(false);
				setTipoAlerta('success');
				setMensagemAlerta(strings.examePriorizado);
				setAlerta(true);
				setOpcoesAlerta([
					{
						title: strings.ok,
						onClick: () => setAlerta(false)
					}
				]);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setTipoAlerta('error');
				setTituloAlerta(strings.erro);
				setMensagemAlerta(strings.erroPriorizarExame);
				setOpcoesAlerta([{ title: strings.ok, onClick: () => setAlerta(false) }]);
				setAlerta(true);
			});
	};

	const handleClickPriorizar = () => {
		// Carrega a lista de motivos de priorização de exames
		axios
			.get(`${EXAMES_API_BASE_URL}/motivo-priorizacao`, { params: { siglaModalidade: exame.sigla_modalidade }, headers: getHeaders() })
			.then((response) => {
				if (response.data) {
					const motivoPadrao = response.data.filter((motivo) => motivo.padrao);
					setTituloAlerta(strings.atencao);
					setTipoAlerta('alert');
					setMensagemAlerta(strings.confirmarPriorizarExame);
					setOpcoesAlerta([
						{
							title: strings.sim,
							onClick: () => {
								priorizarExame(motivoPadrao[0]);
								setAlerta(false);
							}
						},
						{
							title: strings.nao,
							onClick: () => setAlerta(false)
						}
					]);
					setAlerta(true);
				}
			})
			.catch((err) => console.log(err));
	};

	const imprimir = (exame) => {
		if (exame.imprime_exame === SIM) {
			if (MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade)) {
				Utils.imprimirFn(exame, () => setLoading(false), true);
				setLoadingMessage(strings.gerandoDocumento);
				setLoading(true);
				return;
			}

			// Essa verificação funcionará somente para exames de radiologia
			setTituloAlerta(strings.atencao);
			setMensagemAlerta(strings.mensagemImprimirImagensExame);
			setTipoAlerta('alert');
			setOpcoesAlerta([
				{
					title: strings.sim,
					onClick: () => {
						setLoading(true);
						Utils.imprimirFn(exame, () => setLoading(false), true);
						setAlerta(false);
					}
				},
				{
					title: strings.nao,
					onClick: () => {
						setLoading(true);
						Utils.imprimirFn(exame, () => setLoading(false), false);
						setAlerta(false);
					}
				}
			]);
			setAlerta(true);
			return;
		}
		setLoading(true);
		Utils.imprimirFn(exame, () => setLoading(false), false);
	};

	const imprimirProtocolo = (exame) => {
		setLoadingMessage(strings.gerandoDocumento);
		setLoading(true);
		Utils.imprimirProtocoloFn(exame, () => setLoading(false));
	};

	const callbackPacienteInformado = () => {
		carregarExame(false);
		if (!exameModalPaciente.nome_medico_solicitante) {
			setExameModalSolicitante({ ...exameModalPaciente });
		}
		setExameModalPaciente(null);
	};

	const callbackSolicitanteInformado = () => {
		carregarExame(false);
		setExameModalSolicitante(null);
	};

	return (
		<SttContainer>
			{!contextoLaudo && (
				<SttButton
					type="button"
					variant="outlined"
					color="primary"
					onClick={voltar}
					nomarginleft="true"
				>
					{strings.voltar}
				</SttButton>
			)}
			{exame && (
				<>
					<SttHeading
						variant="h1"
						color="primary"
						align="center"
					>
						{exame.descricao_exame}
					</SttHeading>

					<SttExpansionPanel
						title={strings.paciente}
						children={
							<div>
								<SttTextItem
									title={strings.nome}
									content={exame.nome_paciente}
								/>
								{exame.data_nascimento_paciente_formatada && (
									<SttTextItem
										title={strings.dataNascimento}
										content={exame.data_nascimento_paciente_formatada}
									/>
								)}
								{exame.idade_paciente && (
									<SttTextItem
										title={strings.idade}
										content={`${exame.idade_paciente} anos`}
									/>
								)}
								{exame.sexo_paciente && (
									<SttTextItem
										title={strings.sexo}
										content={exame.sexo_paciente}
									/>
								)}
								{exame.cartao_sus_paciente && (
									<SttTextItem
										title={strings.cns}
										content={exame.cartao_sus_paciente}
									/>
								)}
							</div>
						}
					/>

					<SttExpansionPanel
						title={strings.dadosExame}
						children={
							<div>
								<SttTextItem
									title={strings.modalidade}
									content={exame.descricao_modalidade}
								/>
								<SttTextItem
									title={strings.dataExame}
									content={`${exame.data_exame} ${global.gConfig.sufixo_data_hora}`}
								/>
								<div className={classes.protocolo}>
									<SttTextItem
										classContent={classes.fonteProtocolo}
										title={strings.protocolo}
										content={exame.protocolo_rctm}
									/>
									<Tooltip
										title={textoTooltip}
										arrow
										onClick={() => {
											navigator.clipboard.writeText(exame.protocolo_rctm);
											setTextoTooltip(strings.protocoloCopiado);
										}}
										onClose={(e) => setTextoTooltip(strings.copiar)}
									>
										<FileCopyIcon className={classes.fileCopyIcon} />
									</Tooltip>
								</div>
								<SttTextItem
									title={strings.requisicao}
									content={exame.numero_requisicao}
								/>
								<SttTextItem
									title={strings.instituicao}
									content={exame.nome_instituicao}
								/>
								{exame.nome_medico_solicitante && (
									<SttTextItem
										title={strings.medicoSolicitante}
										content={exame.nome_medico_solicitante}
									/>
								)}
								{exame.nome_medico_executor && (
									<SttTextItem
										title={strings.medicoExecutor}
										content={exame.nome_medico_executor}
									/>
								)}
								{exame.indicacao_clinica && (
									<SttTextItem
										title={strings.indicacaoClinica}
										content={exame.indicacao_clinica}
									/>
								)}
							</div>
						}
					/>

					{!exame.valido && (
						<SttNotification
							severity="info"
							className={classes.notification}
						>
							<SttAlertTitle>{strings.exameInvalidado}</SttAlertTitle>
							<div className={classes.notificationContent}>
								<span>{`${strings.motivo}: ${exame.motivo_invalidacao}`}</span>
								<span>{`${strings.responsavel}: ${exame.responsavel_invalidacao}`}</span>
								<span>{`${strings.data}: ${exame.data_invalidacao} ${global.gConfig.sufixo_data_hora}`}</span>
							</div>
						</SttNotification>
					)}

					{exame.laudo_html &&
						(exame.laudo.id_funcionario === user.idFuncionario ||
							exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO ||
							contextoLaudo ||
							(exame.situacao_laudo === SITUACAO_LAUDO.LAUDO_TEMPORARIO && temPermissaoRBAC(user, PERMISSOES.VISUALIZA_LAUDO_TEMP)) ||
							(exame.situacao_laudo === SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO &&
								temPermissaoRBAC(user, PERMISSOES.VISUALIZA_LAUDO_TEMP))) && (
							<SttExpansionPanel
								title={strings.laudo}
								children={<div dangerouslySetInnerHTML={{ __html: exame.laudo_html }}></div>}
							/>
						)}

					{temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_IMAGEM) && (
						<SttGrid
							container
							spacing={3}
						>
							<SttGrid
								item
								xs={12}
							>
								<ImageViewer
									anexos={anexos}
									token={token}
									exame={exame}
								/>
							</SttGrid>
						</SttGrid>
					)}

					{contextoLaudo && exame.laudo && (
						<SttGrid
							container
							spacing={3}
						>
							<SttGrid
								item
								xs={12}
							>
								<SttButton
									type="button"
									variant="contained"
									color="primary"
									nomarginleft="true"
									onClick={() => copiarLaudo(id)}
								>
									{strings.copiarLaudo}
								</SttButton>
							</SttGrid>
						</SttGrid>
					)}

					{adicionarIndicacaoClinica && (
						<IndicacaoClinica
							exame={exame}
							callback={() => {
								setAdicionarIndicacaoClinica(false);
								carregarExame();
							}}
						/>
					)}

					{adicionarAnexo && (
						<Anexo
							exame={exame}
							anexos={anexos}
							callback={(dados, done) => {
								if (Array.isArray(dados)) {
									setAnexos(dados);
								}
								setAdicionarAnexo(!done);
							}}
						/>
					)}

					{direcionarExame && (
						<Direcionamento
							exame={exame}
							callback={() => {
								setDirecionarExame(false);
								carregarExame();
							}}
						/>
					)}

					{!contextoLaudo && exame.valido && (
						<SttGrid
							container
							spacing={1}
						>
							<SttGrid item>
								<SttButton
									variant="contained"
									color="secondary"
									nomarginleft="true"
									onClick={() => imprimir(exame)}
								>
									{strings.imprimir}
								</SttButton>
							</SttGrid>
							<SttGrid item>
								<SttButton
									variant="contained"
									color="secondary"
									nomarginleft
									onClick={() => imprimirProtocolo(exame, setGerandoPdf)}
								>
									{strings.protocolo}
								</SttButton>
							</SttGrid>
							{exame.possui_imagem === SIM && (
								<>
									{/* VISUALIZADOR WEASIS */}
									<SttGrid item>
										<Weasis
											exame={exame}
											token={token}
										/>
									</SttGrid>

									{/* VISUALIZADOR OVIYAM */}
									<SttGrid item>
										<Oviyam
											exame={exame}
											token={token}
										/>
									</SttGrid>

									{/* DOWNLOAD DICOM */}
									<SttGrid item>
										<Download exame={exame} />
									</SttGrid>
								</>
							)}

							{Object.keys(global.gConfig.urls_modulos).includes(exame.sigla_modalidade) && (
								<SttGrid item>
									<SttButton
										variant="contained"
										color="primary"
										nomarginleft
										onClick={() => abrirExameModuloOrigem()}
									>
										{`${strings.abrirModulo} ${exame.descricao_modalidade.toLowerCase()}`}
									</SttButton>
								</SttGrid>
							)}

							{podePriorizar && (
								<SttGrid item>
									<SttButton
										variant="contained"
										color="secondary"
										nomarginleft
										onClick={handleClickPriorizar}
									>
										{strings.priorizar}
									</SttButton>
								</SttGrid>
							)}

							{podeDirecionar && (
								<SttGrid item>
									<SttButton
										variant="contained"
										color="secondary"
										nomarginleft
										onClick={() => setDirecionarExame(true)}
									>
										{(!exame.id_funcionario_direcionado && strings.direcionar) || strings.devolverListaGeral}
									</SttButton>
								</SttGrid>
							)}

							{podeAddAnexo && (
								<SttGrid item>
									<SttButton
										variant="contained"
										color="primary"
										nomarginleft
										onClick={() => setAdicionarAnexo(true)}
									>
										{strings.anexo}
									</SttButton>
								</SttGrid>
							)}

							{podeVisualizarIndicacaoClinica && (
								<SttGrid item>
									<SttButton
										variant="contained"
										color="primary"
										nomarginleft
										onClick={() => setAdicionarIndicacaoClinica(true)}
									>
										{strings.indicacaoClinica}
									</SttButton>
								</SttGrid>
							)}

							{exame.descricao_tipo_modalidade !== DESCRICAO_TIPO_MODALIDADE_TELEDIAGNOSTICO &&
								temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME) && (
									<SttGrid item>
										<SttButton
											variant="contained"
											color="secondary"
											nomarginleft
											danger={+true}
											onClick={() => {
												setInvalidar(true);
											}}
										>
											{strings.invalidar}
										</SttButton>
									</SttGrid>
								)}
						</SttGrid>
					)}
				</>
			)}
			<div className={classes.carregando}>
				<SttLoading
					open={loading}
					text={loadingMessage}
				/>
				<SttLoading
					open={gerandoPdf}
					text={strings.gerandoDocumento}
				/>
			</div>

			{invalidar && (
				<Invalidar
					invalidar={invalidar}
					setInvalidar={setInvalidar}
					idExame={exame.id}
					callback={voltar}
					modalidade={exame.sigla_modalidade}
				/>
			)}

			<SttAlerta
				open={alerta}
				title={tituloAlerta}
				message={mensagemAlerta}
				type={tipoAlerta}
				options={opcoesAlerta}
				onClose={() => setAlerta(false)}
			/>

			{!!exameModalPaciente && (
				<ModalComplementoPaciente
					fecharModal={() => setExameModalPaciente(null)}
					exame={exameModalPaciente}
					callbackPacienteInformado={callbackPacienteInformado}
					callbackFecharModal={() => {
						if (!exameModalPaciente.nome_medico_solicitante) {
							setExameModalSolicitante({ ...exameModalPaciente });
						}
						setExameModalPaciente(null);
					}}
				/>
			)}
			{!!exameModalSolicitante && (
				<ModalComplementoSolicitante
					fecharModal={() => setExameModalSolicitante(null)}
					exame={exameModalSolicitante}
					callbackSolicitanteInformado={callbackSolicitanteInformado}
				/>
			)}
		</SttContainer>
	);
};

Visualizacao.propTypes = {
	strings: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		assinaturaDigital: state.assinaturaDigital.config,
		user: state.index.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAtualizarBusca: (atualizar) => dispatch(setAtualizarBuscaAction(atualizar)),
		setUrlOhif: (id, url) => dispatch(setUrlOhif({ id, url }))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Visualizacao);
