import React, { useRef, memo, lazy, Suspense, useContext, useState } from 'react';
import { Formik } from 'formik';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttFormHelperText,
    SttCircularProgress,
    SttTranslateHook,
    SttHeading,
    SttAlerta
} from '@stt-componentes/core';
import { CAMPOS_PACIENTE } from './camposPacientes';
import { initialValues } from './initialValues';
import { makeStyles } from '@material-ui/core/styles';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { getHeaders } from 'src/request';
import { useHistory } from "react-router-dom";

const Paciente = lazy(() => import('@stt-componentes/paciente'));
const Solicitante = lazy(() => import('./solicitante'));
const Exame = lazy(() => import('./geral'));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    buttonWrapper: {
        marginTop: theme.spacing(2)
    },
    header: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
}));

const campos = CAMPOS_PACIENTE;

const EnvioExameAgendado = () => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const history = useHistory();
    const schema = validationSchema(strings, campos.camposCadastro);
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const [alerta, setAlerta] = useState(false);
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('');
    const [onCloseAlerta, setOnCloseAlerta] = useState(null);

    const secoesAbertas = useSignal({
        'paciente': true,
        'solicitante': false,
        'exame': false,
    });

    const secaoPaciente = useRef(null);
    const secaoSolicitante = useRef(null);
    const secaoExame = useRef(null);

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas.value
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = estado;
        secoesAbertas.value = novoSecoesAbertas;
    }

    const verificarSecoesComErro = (validateForm) => {
        validateForm().then((retorno) => {
            let node = null;

            if (retorno['paciente']) {
                abrirSecao('paciente', true);
                node = secaoPaciente.current;
            } else if (retorno['solicitante']) {
                abrirSecao('solicitante', true);
                node = secaoSolicitante.current;
            } else if (retorno['exame']) {
                abrirSecao('exame', true);
                node = secaoExame.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 200);
            }
        });
    }

    const voltar = () => {
        history.goBack();
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                console.log(data)
                // setSubmitting(true);
                let dados = { ...data };
                if (dados.paciente) {
                    if (dados.paciente.cpf) {
                        dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
                    }
                    if (dados.paciente.cep) {
                        dados.paciente.cep = dados.paciente.cep.replace(/\D+/g, '');
                    }
                }
                let tipoAlerta = '';
                let tituloAlerta = '';
                let mensagemAlerta = '';
                let options = [];
                let onClose = () => { };

                axios.post(`${EXAME_API_BASE_URL}/exame/agendado`, dados, { headers: getHeaders() })
                    .then((response) => {
                        tipoAlerta = 'success';
                        options = [{
                            title: strings.ok,
                            onClick: () => {
                                setAlerta(false);
                                voltar();
                            }
                        }];
                        onClose = () => {
                            setAlerta(false);
                            voltar();
                        }

                        tituloAlerta = strings.sucesso;
                        mensagemAlerta = response.data.message;
                        resetForm();
                    })
                    .catch(err => {
                        const { response } = err;
                        let msg = strings.mensagemErroGeral;
                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const dadosResposta = response.data;
                                let arrMensagem = [];
                                dadosResposta.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                msg = arrMensagem.join('\n');
                                tipoAlerta = 'error';
                                tituloAlerta = dadosResposta.message;
                                mensagemAlerta = msg;
                            } else {
                                tipoAlerta = 'error';
                                tituloAlerta = strings.erro;
                                mensagemAlerta = msg;
                            }
                        } else {
                            tipoAlerta = 'error';
                            tituloAlerta = strings.erro;
                            mensagemAlerta = msg;
                        }
                        options = [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setAlerta(false);
                                }
                            }
                        ];
                        onClose = () => {
                            setAlerta(false);
                        }
                    })
                    .finally(() => {
                        setSubmitting(false);
                        setMensagemAlerta(mensagemAlerta);
                        setTipoAlerta(tipoAlerta);
                        setTituloAlerta(tituloAlerta);
                        setAlerta(true);
                        setOpcoesAlerta(options);
                        setOnCloseAlerta(() => onClose);
                    });
            }}
        >
            {
                ({
                    values,
                    isSubmitting,
                    handleSubmit,
                    errors,
                    touched,
                    submitCount,
                    validateForm,
                    setFieldValue
                }) => {
                    return (
                        <SttContainer>
                            <SttButton
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={voltar}
                                nomarginleft="true"
                            >
                                {strings.voltar}
                            </SttButton>
                            <form onSubmit={handleSubmit} noValidate>
                                <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.agendamentoExame}</SttHeading>

                                {/* Dados do paciente */}
                                <SttExpansionPanel
                                    title={strings.dadosPaciente}
                                    opened={secoesAbertas.value['paciente']}
                                    classegriditem={classes.fullWidth}
                                    callback={estadoInterno => abrirSecao('paciente', estadoInterno)}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoPaciente}></div>
                                            <Paciente
                                                strings={strings}
                                                headers={getHeaders()}
                                                usarTipoContato
                                                persistirParametrosBusca
                                                campos={campos}
                                                formExterno={{
                                                    paciente: values[PACIENTE],
                                                    setFieldValue,
                                                    errors: errors[PACIENTE],
                                                    submitCount
                                                }}
                                            />

                                            {
                                                touched.paciente && errors.paciente && errors.paciente.nome &&
                                                <SttFormHelperText error>
                                                    {strings.pacienteObrigatorio}
                                                </SttFormHelperText>
                                            }
                                        </Suspense>
                                    }
                                />
                                <Divider />
                                {/* Solicitante */}
                                <SttExpansionPanel
                                    title={strings.solicitante}
                                    opened={secoesAbertas.value['solicitante']}
                                    classegriditem={classes.fullWidth}
                                    callback={estadoInterno => abrirSecao('solicitante', estadoInterno)}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoSolicitante}></div>
                                            <Solicitante />
                                        </Suspense>
                                    }
                                />
                                <Divider />

                                {/* Dados do exame */}
                                <SttExpansionPanel
                                    title={strings.dadosExame}
                                    opened={secoesAbertas.value['exame']}
                                    classegriditem={classes.fullWidth}
                                    callback={estadoInterno => abrirSecao('exame', estadoInterno)}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoExame}></div>
                                            <Exame />
                                        </Suspense>
                                    }
                                />
                                <Divider />

                                <Divider />
                                <div className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => verificarSecoesComErro(validateForm)}
                                    >
                                        {strings.enviar}
                                    </SttButton>
                                </div>
                            </form>
                            <SttLoading
                                open={isSubmitting}
                                text={strings.salvandoSolicitacao}
                            />
                            <SttAlerta
                                open={alerta}
                                title={tituloAlerta}
                                message={mensagemAlerta}
                                type={tipoAlerta}
                                options={opcoesAlerta}
                                onClose={onCloseAlerta || (() => setAlerta(false))}
                            />
                        </SttContainer>
                    )
                }
            }
        </Formik>
    );
};

export default EnvioExameAgendado;