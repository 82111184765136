export const TIPO_ABA_EXAME = {
    INSTITUICAO: 'INSTITUICAO',
    REDE: 'REDE',
    PERFIL: 'PERFIL'
}

export const VINCULO_PERFIL = {
    GLOBAL: 'G',
    INSTITUICAO: 'I',
    USUARIO: 'U',
    REDE: 'R',
    MUNICIPAL: 'M'
}

export const SITUACAO_LAUDO = {
    SEM_LAUDO: 'N',
}

export const LAUDO_EM_EMISSAO = {
    SIM: 'S',
    NAO: 'N'
}

export const SITUACAO = {
    VALIDO: 'V',
    INVALIDO: 'I'
}

export const ORIGEM = {
    PACS: 'D',
    AGENDADOS: 'I',
    TELEDIAGNOSTICO: 'M'
}

export const TIPO_BUSCA = {
    TODOS_TERMOS: 'T',
    QUALQUER_TERMO: 'Q'
}

export const PAGINACAO = {
    REGISTROS_PAGINA: 'REGISTROS_PAGINA'
}
