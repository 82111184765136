import React, { useState, memo, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { connect } from 'react-redux';
import { setParametrosPesquisa as setParametrosPesquisaAction, setPesquisaAvancada } from '../../../reducers/actions/exame';
import { Formik } from 'formik';
import { useMoment } from '../../../hooks';
import axios from 'axios';
import { getHeaders } from '../../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import { estadoInicialForm } from './estadoInicialForm';
import { temPerfilRBAC, temPermissaoRBAC } from '../../../secutity/acl';
import { SttGrid, SttButton, SttAlerta, SttTranslateHook, SttHidden } from '@stt-componentes/core';
import { EXAMES_DIRECIONADOS, EXAMES_VALIDADOS, EXAMES_PRIORIZADOS, SOMENTE_COM_IMAGENS, LAUDADO_POR_MIM } from './fieldNames';
import { PERFIL, PERMISSOES, SIM, SITUACAO_LAUDO, VINCULO_PERFIL } from 'src/common/Constants';
import FormGeral from './form-geral';
import FormExecutor from './form-executor';
import FormAdm from './form-adm';

const Alerta = memo((props) => {
    return <SttAlerta {...props} />;
});

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    card: {
        height: '100%'
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const FormPesquisa = (props) => {
    const {
        user,
        callbackBusca,
        callbackAndamento,
        page,
        count,
        buscar,
        filtros,
        setParametrosPesquisa,
        pesquisaAvancada,
        setPesquisaAvancada,
        resetPageCount,
        sort,
        sortOrder
    } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const schema = validationSchema(strings);
    const filtrosIniciais = estadoInicialForm(global.gConfig, user);
    const formRef = useRef();
    const classes = useStyles();
    const moment = useMoment();

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    };

    // Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([
        {
            title: strings.ok,
            onClick: handleCloseAlerta
        }
    ]);

    // Filtros
    const [filtroPorDescricao, setFiltroPorDescricao] = useState(false);
    const [filtroPorRede, setFiltroPorRede] = useState(false);
    const [filtroPorFuncionario, setFiltroPorFuncionario] = useState(false);
    const [filtroValidado, setFiltroValidado] = useState(false);
    const [filtroPriorizado, setFiltroPriorizado] = useState(false);

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    useEffect(() => {
        if (!filtros) {
            setParametrosPesquisa(filtrosIniciais);
        }

        if (
            temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
            temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
            temPerfilRBAC(user, PERFIL.ADMINISTRADOR_TELEMEDICINA)
        ) {
            setFiltroPorFuncionario(true);
            setFiltroPorRede(true);
            setFiltroPorDescricao(true);
        }
        if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL)) {
            setFiltroPorDescricao(true);
        }
        if (temPerfilRBAC(user, PERFIL.TECNICO) || temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) {
            setFiltroValidado(true);
            setFiltroPriorizado(true);
        }
    }, []);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const gerarFiltroImplicito = (filtros) => {
        if (
            temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR) ||
            temPerfilRBAC(user, PERFIL.MEDICO_VISUALIZADOR) ||
            temPerfilRBAC(user, PERFIL.MEDICO_RESIDENTE) ||
            temPerfilRBAC(user, PERFIL.TECNICO) ||
            temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL)
        ) {
            const perfisInstituicao = user.perfisRBAC.filter((perfil) => perfil.vinculo === VINCULO_PERFIL.INSTITUICAO);
            const instituicoesHabilitadas = [];
            perfisInstituicao.forEach((perfil) => {
                perfil.instituicoes.forEach((instituicao) => {
                    if (!instituicoesHabilitadas.includes(instituicao.id)) {
                        instituicoesHabilitadas.push(instituicao.id);
                    }
                });
            });
            filtros.instituicao = instituicoesHabilitadas.join('|');
        } else if (temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) {
            filtros.solicitante = user.idFuncionario;
        } else if (temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) {
            filtros.laudador = user.idFuncionario;
        }
    };

    const estruturarFiltros = (dados) => {
        let filtrosPesquisa = {
            start: dados.start,
            count: dados.count,
            sort: dados.sort,
            sortOrder: dados.sortOrder
        };

        // Se é administrador local, é aplicado filtro implícito pela sua instituição
        if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL)) {
            gerarFiltroImplicito(filtrosPesquisa);
        } else if (
            !temPermissaoRBAC(user, PERMISSOES.HISTORICO_PACIENTE) ||
            (!dados['cpf-paciente'] && !dados['cns-paciente'] && !dados.paciente.trim() && !dados.protocolo)
        ) {
            // Se o usuário tem acesso ao histórico de exames do paciente ou se ele está buscando por exames de um paciente específico ou por protocolo,
            // os filtros implícitos não são aplicados
            gerarFiltroImplicito(filtrosPesquisa);
        }

        // Se o usuário está buscando por exames de um paciente específico ou por um protrocolo,
        // a data inicial do exame é ignorada
        if (!dados.protocolo && !dados['cpf-paciente'] && !dados['cns-paciente'] && dados['data-exame-de']) {
            filtrosPesquisa['data-exame-de'] = moment(dados['data-exame-de']).format('YYYY-MM-DD');
        }
        if (dados['data-exame-ate']) {
            filtrosPesquisa['data-exame-ate'] = moment(dados['data-exame-ate']).format('YYYY-MM-DD');
        }
        if (dados['data-laudo-de']) {
            filtrosPesquisa['data-laudo-de'] = moment(dados['data-laudo-de']).format('YYYY-MM-DD');
        }
        if (dados['data-laudo-ate']) {
            filtrosPesquisa['data-laudo-ate'] = moment(dados['data-laudo-ate']).format('YYYY-MM-DD');
        }

        if (dados.protocolo) {
            filtrosPesquisa.protocolo = dados.protocolo;
        }
        if (dados['cpf-paciente']) {
            filtrosPesquisa['cpf-paciente'] = dados['cpf-paciente'];
        }
        if (dados['cns-paciente']) {
            filtrosPesquisa['cns-paciente'] = dados['cns-paciente'];
        }
        if (dados.paciente.trim()) {
            filtrosPesquisa.paciente = dados.paciente.trim();
        }

        if (dados.uf) {
            filtrosPesquisa.uf = dados.uf.id;
        }
        if (dados.municipio) {
            filtrosPesquisa.municipio = dados.municipio.id;
        }
        if (dados.instituicao) {
            filtrosPesquisa.instituicao = dados.instituicao.id;
        }
        if (dados.modalidade) {
            filtrosPesquisa.modalidade = dados.modalidade.id;
        }
        if (dados['funcionario-envio'].trim()) {
            filtrosPesquisa['funcionario-envio'] = dados['funcionario-envio'].trim();
        }
        if (dados.requisicao) {
            filtrosPesquisa.requisicao = dados.requisicao;
        }
        if (dados.rede) {
            filtrosPesquisa.rede = dados.rede.id;
        }
        if (dados['situacao-laudo'].length > 0) {
            filtrosPesquisa['situacao-laudo'] = dados['situacao-laudo'].join('|');
        }
        if (dados.atrasado) {
            filtrosPesquisa.atrasado = dados.atrasado;
            filtrosPesquisa['situacao-laudo'] = SITUACAO_LAUDO.SEM_LAUDO;
        }
        if (dados['numero-exame']) {
            filtrosPesquisa['numero-exame'] = dados['numero-exame'];
        }
        if (dados.situacao.length > 0) {
            filtrosPesquisa.situacao = dados.situacao.join('|');
        }
        if (dados.origem.length > 0) {
            filtrosPesquisa.origem = dados.origem.join('|');
        }
        if (dados['descricao-exame'].trim()) {
            filtrosPesquisa['descricao-exame'] = dados['descricao-exame'].trim();
        }
        if (dados[EXAMES_DIRECIONADOS]) {
            filtrosPesquisa['profissional-direcionado'] = user.idFuncionario;
        }
        if (dados[EXAMES_VALIDADOS]) {
            filtrosPesquisa[EXAMES_VALIDADOS] = SIM;
        }
        if (dados[EXAMES_PRIORIZADOS]) {
            filtrosPesquisa[EXAMES_PRIORIZADOS] = SIM;
        }
        if (dados[SOMENTE_COM_IMAGENS]) {
            filtrosPesquisa[SOMENTE_COM_IMAGENS] = SIM;
        }
        if (dados[LAUDADO_POR_MIM]) {
            filtrosPesquisa[LAUDADO_POR_MIM] = user.idFuncionario;
        }

        return filtrosPesquisa;
    };

    return (
        filtros && (
            <Formik
                innerRef={formRef}
                initialValues={filtros}
                validationSchema={schema}
                enableReinitialize
                onSubmit={(data, { setSubmitting }) => {
                    data.start = page * count;
                    data.count = count;
                    data.page = page;
                    data.sort = sort;
                    data.sortOrder = sortOrder;
                    setSubmitting(true);
                    callbackAndamento(true);
                    // Salva os parâmetros da pesquisa para manter estado do
                    // formulário durante a navegação entre páginas
                    setParametrosPesquisa(data);

                    let params = estruturarFiltros(data, sort, sortOrder);
                    let tipoAlertaPesquisa = '';
                    let tituloAlertaPesquisa = '';
                    let mensagemAlertaPesquisa = '';
                    axios
                        .get(`${EXAME_API_BASE_URL}/exames`, { params: params, headers: getHeaders() })
                        .then((response) => {
                            const dados = response.data.data;
                            callbackBusca(dados);
                        })
                        .catch((err) => {
                            const { response } = err;
                            let msg = strings.erroGenerico;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    const erro = response.data;
                                    let arrMensagem = [];
                                    erro.errors.forEach((error) => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    tipoAlertaPesquisa = 'error';
                                    tituloAlertaPesquisa = erro.message;
                                    mensagemAlertaPesquisa = msg;
                                } else {
                                    tipoAlertaPesquisa = 'error';
                                    tituloAlertaPesquisa = strings.erro;
                                    mensagemAlertaPesquisa = msg;
                                }
                            } else {
                                tipoAlertaPesquisa = 'error';
                                tituloAlertaPesquisa = strings.erro;
                                mensagemAlertaPesquisa = msg;
                            }
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: handleCloseAlerta
                                }
                            ]);
                            setTipoAlerta(tipoAlertaPesquisa);
                            setTituloAlerta(tituloAlertaPesquisa);
                            setMensagemAlerta(mensagemAlertaPesquisa);
                            setMostrarAlerta(true);
                        })
                        .finally(() => {
                            setSubmitting(false);
                            callbackAndamento(false);
                        });
                }}
            >
                {({ isSubmitting, handleSubmit, resetForm }) => {
                    return (
                        <>
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                            >
                                {temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR) || temPerfilRBAC(user, PERFIL.MEDICO_RESIDENTE) ? (
                                    <FormExecutor pesquisaAvancada={pesquisaAvancada} />
                                ) : temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ? (
                                    <FormAdm
                                        pesquisaAvancada={pesquisaAvancada}
                                        filtroPorRede={filtroPorRede}
                                        filtroPorFuncionario={filtroPorFuncionario}
                                        filtroPorDescricao={filtroPorDescricao}
                                        filtroValidado={filtroValidado}
                                        filtroPriorizado={filtroPriorizado}
                                    />
                                ) : (
                                    <FormGeral
                                        filtroPorRede={filtroPorRede}
                                        filtroPorFuncionario={filtroPorFuncionario}
                                        filtroPorDescricao={filtroPorDescricao}
                                        filtroValidado={filtroValidado}
                                        filtroPriorizado={filtroPriorizado}
                                    />
                                )}
                                <SttGrid
                                    container
                                    spacing={3}
                                >
                                    <SttGrid
                                        item
                                        xs={12}
                                        className={classes.buttonWrapper}
                                    >
                                        <SttHidden smDown>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                nomarginleft="true"
                                                onClick={resetPageCount}
                                            >
                                                {strings.pesquisar}
                                            </SttButton>
                                        </SttHidden>
                                        <SttHidden mdUp>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={resetPageCount}
                                            >
                                                {strings.pesquisar}
                                            </SttButton>
                                        </SttHidden>

                                        <SttButton
                                            type="button"
                                            variant="outlined"
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                resetPageCount();
                                                resetForm({
                                                    values: filtrosIniciais
                                                });
                                                callbackBusca(null);
                                            }}
                                        >
                                            {strings.limpar}
                                        </SttButton>
                                        {(temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.MEDICO_RESIDENTE)) && (
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => setPesquisaAvancada(!pesquisaAvancada)}
                                            >
                                                {strings.pesquisaAvancada}
                                                {pesquisaAvancada ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </SttButton>
                                        )}
                                    </SttGrid>
                                </SttGrid>
                            </form>
                            <Alerta
                                open={mostrarAlerta}
                                title={tituloAlerta}
                                message={mensagemAlerta}
                                type={tipoAlerta}
                                options={opcoesAlerta}
                                onClose={handleCloseAlerta}
                            />
                        </>
                    );
                }}
            </Formik>
        )
    );
};

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    buscar: PropTypes.bool.isRequired,
    filtros: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetPageCount: PropTypes.func.isRequired,
    sort: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.exame.filtros,
        pesquisaAvancada: state.exame.pesquisaAvancada
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosPesquisa: (parametros) => dispatch(setParametrosPesquisaAction(parametros)),
        setPesquisaAvancada: (avancada) => dispatch(setPesquisaAvancada(avancada))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPesquisa);
