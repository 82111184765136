import React from 'react';
import Aba from '../../componentes/worklist/aba';
import { SttTabs } from '@stt-componentes/core';

const Worklist = () => {

    return (
        <SttTabs
            abas={[{
                titulo: 'Pesquisa',
                conteudo: Aba,
                key: 'Pesquisa',
                // adicionarAba: addTab,
                permanente: true,
            }]}
            canClose={true}
            // handleChangeAbaAtiva={setActiveTab}
            // abaAtiva={activeTab}
            // handleCloseTab={(indice) => removeTab(indice)}
            permanente={true}
            preload={false}
        />
    );
}

export default Worklist;