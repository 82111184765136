import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from 'formik';
import {
    SttGrid,
    SttTranslateHook,
    SttCardHeader,
    SttCardContent,
    SttCard,
    SttCollapse
} from '@stt-componentes/core';
import DataInicialExame from './campos/data-inicial-exame';
import DataFinalExame from './campos/data-final-exame';
import Uf from './campos/uf';
import Municipio from './campos/municipio';
import Instituicao from './campos/instituicao';
import Requisicao from './campos/requisicao';
import Protocolo from './campos/protocolo';
import Modalidade from './campos/modalidade';
import DescricaoExame from './campos/descricao-exame';
import Paciente from './campos/paciente';
import ResponsavelEnvio from './campos/responsavel-envio';

import {
    UF,
    MUNICIPIO,
    REQUISICAO,
    PROTOCOLO,
    NUMERO_EXAME,
    DESCRICAO_EXAME,
} from './fieldNames';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%'
    },
    containerExame: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    }
}));

const FormAdmin = (props) => {
    const { pesquisaAvancada } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue } = useFormikContext();
    const classes = useStyles();

    useEffect(() => {
        if (!pesquisaAvancada) {
            setFieldValue(UF, null);
            setFieldValue(MUNICIPIO, null);
            setFieldValue(REQUISICAO, '');
            setFieldValue(PROTOCOLO, '');
            setFieldValue(NUMERO_EXAME, '');
            setFieldValue(DESCRICAO_EXAME, '');
        }
    }, [pesquisaAvancada]);

    return (
        <SttGrid container spacing={1}>
            <SttGrid item xs={12} md={12} lg={6}>
                <SttCard variant="outlined" className={classes.card}>
                    <SttCardHeader subheader={strings.exame} />
                    <SttCardContent>
                        <SttGrid container spacing={1}>
                            <SttGrid item xs={12} sm={4} md={3} lg={4}>
                                <DataInicialExame />
                            </SttGrid>
                            <SttGrid item xs={12} sm={4} md={3} lg={4}>
                                <DataFinalExame />
                            </SttGrid>
                            <SttGrid item xs={12} sm={4} md={3} lg={4}>
                                <Modalidade />
                            </SttGrid>
                        </SttGrid>
                    </SttCardContent>
                </SttCard>
            </SttGrid>
            <SttGrid item xs={12} md={12} lg={6}>
                <Paciente />
            </SttGrid>
            <SttGrid item xs={12}>
                <SttCollapse in={pesquisaAvancada}>
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardHeader subheader={strings.pesquisaAvancada} />
                        <SttCardContent>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={3} md={3} lg={1}>
                                    <Uf />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={4} lg={3}>
                                    <Municipio />
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={3} lg={4}>
                                    <Instituicao />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <Requisicao />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <Protocolo />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <ResponsavelEnvio />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={2}>
                                    <DescricaoExame />
                                </SttGrid>
                            </SttGrid>
                        </SttCardContent>
                    </SttCard>
                </SttCollapse>
            </SttGrid>
        </SttGrid>
    );
};

export default FormAdmin;