import React, { useState, useContext, useRef, Suspense, lazy } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
	SttTranslateHook,
	SttModal,
	SttButton,
	SttGrid,
	SttLoading,
	SttNotification,
	SttAlerta,
	SttCircularProgress
} from '@stt-componentes/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import HttpStatus from 'http-status-codes';

const validationSchema = (strings) => {
	return yup
		.object()
		.shape({
			funcionario: yup
				.object()
				.nullable(),
		})
};

const useStyles = makeStyles((theme) => ({
	form: {
		marginTop: theme.spacing(2)
	},
	notificationContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	notification: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	button: {
		marginBottom: theme.spacing(1)
	}
}));

const SttFuncionario = lazy(() => import('@stt-componentes/funcionario'));

const ModalComplementoSolicitante = ({ exame, fecharModal, callbackSolicitanteInformado }) => {
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const classes = useStyles();
	const ref = useRef();

	const schema = validationSchema(strings);

	const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

	// Alerta
	const [alerta, setAlerta] = useState(false);
	const [opcoesAlerta, setOpcoesAlerta] = useState([]);
	const [mensagemAlerta, setMensagemAlerta] = useState('');
	const [tituloAlerta, setTituloAlerta] = useState('');
	const [tipoAlerta, setTipoAlerta] = useState('');
	const [onCloseAlerta, setOnCloseAlerta] = useState(() => setAlerta(false));

	const vincularExameSolicitante = (dados, setSubmitting) => {
		setSubmitting(true);

		const payload = {
			idExame: exame.id,
			funcionario: dados.funcionario
		};

		axios.post(`${EXAME_API_BASE_URL}/exame/vincular-solicitante`, payload, { headers: getHeaders() })
			.then((response) => {
				setTituloAlerta(strings.sucesso);
				setOpcoesAlerta([
					{
						title: strings.ok,
						onClick: () => {
							callbackSolicitanteInformado();
							setAlerta(false);
						}
					}
				]);
				setMensagemAlerta(strings.sucessoVinculoSolicitante);
				setTipoAlerta('success');
				setOnCloseAlerta(() => () => {
					callbackSolicitanteInformado();
					setAlerta(false);
				});
				setAlerta(true);
			})
			.catch((err) => {
				console.log(err);
				const { response } = err;
				let msg = strings.erroGenerico;
				if (response) {
					if (response.status === HttpStatus.BAD_REQUEST) {
						const { data } = response;
						let arrMensagem = [];
						data.errors.forEach((error) => {
							arrMensagem.push(`- ${error.message}`);
						});
						msg = arrMensagem.join('\n');
						setTituloAlerta(data.message);
					} else {
						setTituloAlerta(strings.erro);
					}
				} else {
					setTituloAlerta(strings.erro);
				}
				setTipoAlerta('error');
				setMensagemAlerta(msg);
				setAlerta(true);
				setOnCloseAlerta(() => {
					setAlerta(false);
				});
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<SttModal
			title={strings.vincularMedicoSolicitante}
			open={true}
			outModalClose={fecharModal}
			iconClose={fecharModal}
			maxWidth={'md'}
			fullWidth={true}
			children={
				<Formik
					initialValues={{ funcionario: null }}
					innerRef={ref}
					validationSchema={schema}
					onSubmit={(dados, { setSubmitting }) => {
						setSubmitting(false);
						setTituloAlerta(strings.atencao);
						setOpcoesAlerta([
							{
								title: strings.sim,
								onClick: () => {
									setAlerta(false);
									vincularExameSolicitante(dados, setSubmitting);
								}
							},
							{
								title: strings.nao,
								onClick: () => {
									setAlerta(false);
								}
							}
						]);
						setMensagemAlerta(strings.confirmarEscolhaSolicitanteVinculoExame);
						setTipoAlerta('alert');
						setOnCloseAlerta(() => () => {
							setAlerta(false);
						});
						setAlerta(true);
					}}
					enableReinitialize
				>
					{({
						isSubmitting,
						handleSubmit,
						setSubmitting,
						values,
						setFieldValue,
						...formikEvents
					}) => {
						return (
							<form onSubmit={handleSubmit} noValidate>
								<div className={classes.form}>
									<SttNotification severity="warning" className={classes.notification}>
										<div className={classes.notificationContent}>
											<span>
												{strings.descricaoNecessidadeInformarMedicoSolicitante}
											</span>
										</div>
									</SttNotification>
									<SttGrid container spacing={3}>
										<SttGrid item xs={12}>
											<Suspense fallback={<SttCircularProgress color="primary" />}>
												<SttFuncionario
													strings={strings}
													conselhoTrabalho={['CRM']}
													fieldName={'funcionario'}
													headers={getHeaders()}
													obrigatorio={true}
													label={strings.medicoSolicitante}
													onSelect={(funcionario) => {
														setFieldValue('funcionario', funcionario || null)
													}}
													formExterno={{
														funcionario: values.funcionario,
														setFieldValue: setFieldValue,
														...formikEvents
													}}
												/>
											</Suspense>
										</SttGrid>
									</SttGrid>
								</div>
								<SttGrid container spacing={2}>
									<SttGrid item xs={12}>
										<SttButton
											type="submit"
											variant="contained"
											color="primary"
											nomarginleft="true"
											disabled={isSubmitting || !values.funcionario}
										>
											{strings.confirmar}
										</SttButton>
										<SttButton
											type="button"
											variant="outlined"
											color="primary"
											disabled={isSubmitting}
											onClick={fecharModal}
										>
											{strings.informarOutroMomento}
										</SttButton>
									</SttGrid>
								</SttGrid>

								<SttLoading
									open={isSubmitting}
									text={strings.salvando}
								/>
								<SttAlerta
									open={alerta}
									title={tituloAlerta}
									message={mensagemAlerta}
									type={tipoAlerta}
									options={opcoesAlerta}
									onClose={onCloseAlerta}
								/>
							</form>
						);
					}}
				</Formik>
			}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user
	};
};

export default connect(mapStateToProps)(ModalComplementoSolicitante);