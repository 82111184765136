export const UF = 'uf';
export const MUNICIPIO = 'municipio';
export const INSTITUICAO = 'instituicao';
export const FUNCIONARIO_ENVIO = 'funcionario-envio';
export const REQUISICAO = 'requisicao';
export const NOME_PACIENTE = 'paciente';
export const CPF_PACIENTE = 'cpf-paciente';
export const CNS_PACIENTE = 'cns-paciente';
export const DATA_EXAME_DE = 'data-exame-de';
export const DATA_EXAME_ATE = 'data-exame-ate';
export const PROTOCOLO = 'protocolo';
export const MODALIDADE = 'modalidade';
export const CATEGORIA = 'categoria';
export const NUMERO_EXAME = 'numero-exame';
export const DESCRICAO_EXAME = 'descricao-exame';
export const TIPO_BUSCA_DESCRICAO = 'tipo-busca-descricao';
