import {
	ID_EXAME,
	ID_INSTITUICAO,
	ID_LAUDO_EMISSAO,
	ID_PACIENTE,
	NOME_PACIENTE,
	DATA_NASCIMENTO_PACIENTE,
	SEXO_PACIENTE,
	CODIGO_MODALIDADE,
	SIGLA_MODALIDADE,
	DESCRICAO_MODALIDADE,
	NUMERO_REQUISICAO,
	TIMESTAMP_INICIO,
	PROFISSIONAL_DIRECIONADO,
	CONTEUDO_TEXTUAL
} from './fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';
import { FIELDS } from '@stt-componentes/decs/dist/lib/fieldNames';

const { DECS } = FIELDS;

export const values = (exame, modelo, timestamp) => {
	let val = {
		[ID_EXAME]: exame.id,
		[ID_INSTITUICAO]: exame.id_instituicao,
		[ID_LAUDO_EMISSAO]: exame.idLaudoEmissao,
		[TIMESTAMP_INICIO]: timestamp,
		[ID_PACIENTE]: exame.id_paciente,
		[NOME_PACIENTE]: exame.nome_paciente,
		[DATA_NASCIMENTO_PACIENTE]: exame.data_nascimento_paciente,
		[SEXO_PACIENTE]: exame.sigla_sexo_paciente,
		[CODIGO_MODALIDADE]: exame.codigo_modalidade,
		[SIGLA_MODALIDADE]: exame.sigla_modalidade,
		[DESCRICAO_MODALIDADE]: exame.descricao_modalidade,
		[NUMERO_REQUISICAO]: exame.numero_requisicao,
		[CID_10]: exame.laudo?.cid10 || [],
		[DECS]: exame.laudo?.decs || [],
		[PROFISSIONAL_DIRECIONADO]: null,
		[CONTEUDO_TEXTUAL]: {}
	};

	modelo.forEach((campo) => {
		val[CONTEUDO_TEXTUAL][campo.secao] = (campo.conteudo || '').replace(/\n/g, '<br>');
	});

	return val;
};
