import React, { useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
	SttTranslateHook,
	SttModal,
	SttMaskedInput,
	SttButton,
	SttGrid,
	SttLoading,
	SttTableHead,
	SttTableCell,
	SttHidden,
	SttTableBody,
	SttTable,
	SttTableRow,
	SttText,
	SttAddButton,
	SttNotification,
	SttAlerta,
	SttDatePicker
} from '@stt-componentes/core';
import { Formik, FastField, Field } from 'formik';
import * as yup from 'yup';
import Moment from 'react-moment';
import HttpStatus from 'http-status-codes';
import moment from 'moment';
import { validateBr } from 'js-brasil';

const validationSchema = (strings) => {
	return yup
		.object()
		.shape({
			cpf: yup
				.string()
				.nullable()
				.test('cpf-valido', strings.cpfInvalido, (cpf) => {
					cpf = cpf || '';
					if (cpf.length === 0) {
						return true;
					}
					return validateBr.cpf(cpf.trim());
				}),
			cns: yup
				.string()
				.nullable()
				.test('cnf-valido', strings.cnsInvalido, (cns) => {
					cns = cns || '';
					if (cns.length === 0) {
						return true;
					}
					return validateBr.cns(cns.trim());
				}),
			dataNascimento: yup
				.date()
				.typeError(strings.dataInvalida)
				.nullable()
				.required(strings.campoObrigatorio)
				.max(new Date(), strings.erroDataFutura)
		})
		.test('cpf-ou-cns', strings.campoObrigatorio, function (values) {
			const { createError } = this;
			const { cpf, cns } = values;
			if ((!cpf || cpf.trim().length === 0) && (!cns || cns.trim().length === 0)) {
				return createError({ path: 'cpf', message: strings.cpfCnsObrigatorio });
			}

			return true;
		})
		.test('cpf-ou-cns', strings.campoObrigatorio, function (values) {
			const { createError } = this;
			const { cpf, cns } = values;
			if ((!cpf || cpf.trim().length === 0) && (!cns || cns.trim().length === 0)) {
				return createError({ path: 'cns', message: strings.cpfCnsObrigatorio });
			}

			return true;
		});
};

const useStyles = makeStyles((theme) => ({
	form: {
		marginTop: theme.spacing(2)
	},
	tableWrapper: {
		overflow: 'auto',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2)
	},
	notificationContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	notification: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	button: {
		marginBottom: theme.spacing(1)
	}
}));

const CONSTANTE_MODO_CPF_PACIENTE = 'C';
const CONSTANTE_MODO_DATA_NASCIMENTO_PACIENTE = 'D';

const ModalComplementoPacienteExame = ({ exame, fecharModal, callbackPacienteInformado, callbackFecharModal = () => { } }) => {
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const classes = useStyles();
	const ref = useRef();

	const schema = validationSchema(strings);

	const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
	const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

	const [listaPacientes, setListaPacientes] = useState([]);
	const [modo, setModo] = useState(exame?.cpf_paciente ? CONSTANTE_MODO_DATA_NASCIMENTO_PACIENTE : CONSTANTE_MODO_CPF_PACIENTE);

	// Alerta
	const [alerta, setAlerta] = useState(false);
	const [opcoesAlerta, setOpcoesAlerta] = useState([]);
	const [mensagemAlerta, setMensagemAlerta] = useState('');
	const [tituloAlerta, setTituloAlerta] = useState('');
	const [tipoAlerta, setTipoAlerta] = useState('');
	const [onCloseAlerta, setOnCloseAlerta] = useState(() => setAlerta(false));

	const [carregando, setCarregando] = useState(false);

	const handleClickSelecionar = (paciente, setSubmitting) => {
		setTituloAlerta(strings.atencao);
		setOpcoesAlerta([
			{
				title: strings.sim,
				onClick: () => {
					setAlerta(false);
					vincularExamePaciente(paciente, setSubmitting);
				}
			},
			{
				title: strings.nao,
				onClick: () => {
					setAlerta(false);
				}
			}
		]);
		setMensagemAlerta(strings.confirmarEscolhaNecessidadeInformarCpfPaciente);
		setTipoAlerta('alert');
		setOnCloseAlerta(() => () => {
			setAlerta(false);
		});
		setAlerta(true);
	};

	const vincularExamePaciente = (paciente, setSubmitting) => {
		setSubmitting(true);

		if (paciente.id_sexo) {
			paciente.genero = {
				id: paciente.id_sexo
			};
		}

		if (paciente.data_nascimento) {
			paciente.dataNascimento = paciente.data_nascimento;
		}

		if (paciente.tipoContato1?.id) {
			paciente.tipoContato1 = paciente.tipoContato1.id;
		}

		const payload = {
			idExame: exame.id,
			paciente: JSON.stringify(paciente)
		};

		axios
			.post(`${EXAME_API_BASE_URL}/exame/vincular-paciente`, payload, { headers: getHeaders() })
			.then((response) => {
				setTituloAlerta(strings.sucesso);
				setOpcoesAlerta([
					{
						title: strings.ok,
						onClick: () => {
							fecharModal();
							callbackPacienteInformado();
							setAlerta(false);
						}
					}
				]);
				setMensagemAlerta(strings.sucessoNecessidadeInformarCpfPaciente);
				setTipoAlerta('success');
				setOnCloseAlerta(() => () => {
					fecharModal();
					callbackPacienteInformado();
					setAlerta(false);
				});
				setAlerta(true);
			})
			.catch((err) => {
				console.log(err);
				const { response } = err;
				let msg = strings.erroGenerico;
				if (response) {
					if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
						const { data } = response;
						let arrMensagem = [];
						data.errors.forEach((error) => {
							arrMensagem.push(`- ${error.message}`);
						});
						msg = arrMensagem.join('\n');
						setTituloAlerta(data.message);
					} else {
						setTituloAlerta(strings.erro);
					}
				} else {
					setTituloAlerta(strings.erro);
				}
				setTipoAlerta('error');
				setMensagemAlerta(msg);
				setAlerta(true);
				setOpcoesAlerta([
					{
						title: strings.ok,
						onClick: () => {
							setAlerta(false);
						}
					}
				]);
				setOnCloseAlerta(() => () => {
					setAlerta(false);
				});
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	const buscarPacientePorCpfCns = (dados, setSubmitting) => {
		setSubmitting(true);
		const url = `${UTILITARIOS_API_BASE_URL}/paciente-definitivo`;
		const params = {};
		if (dados.cpf) {
			params.cpf = dados.cpf.replace(/[.-]/g, '');
		}

		if (dados.cns) {
			params.cns = dados.cns.replace(/[ .-]/g, '');
		}

		axios
			.get(url, { params, headers: getHeaders() })
			.then((response) => {
				if (response.data) {
					const { itens } = response.data.data;
					setListaPacientes(itens);
				}
			})
			.catch(() => {
				setListaPacientes([]);
				// Vincular CPF e data de nascimento
				setTituloAlerta(strings.atencao);
				setOpcoesAlerta([
					{
						title: strings.sim,
						onClick: () => {
							setAlerta(false);
							setModo(CONSTANTE_MODO_DATA_NASCIMENTO_PACIENTE);
						}
					},
					{
						title: strings.nao,
						onClick: () => {
							setAlerta(false);
						}
					}
				]);
				setMensagemAlerta(strings.pacienteNaoEncontradoInformarCpfCns);
				setTipoAlerta('alert');
				setOnCloseAlerta(() => () => {
					setAlerta(false);
				});
				setAlerta(true);
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	const handleValidateCpfCns = async (values, setSubmitting, validateForm) => {
		const errors = await validateForm();

		if (!errors.cpf && !errors.cns) {
			buscarPacientePorCpfCns(values, setSubmitting);
		}
	};

	const submitForm = (dados, { setSubmitting }) => {
		const { cpf, dataNascimento } = dados;

		const payload = {
			cpf: cpf.replace(/\D+/g, ''),
			data_nascimento: moment(dataNascimento, 'DD-MM-YYYY').toISOString(),
			tipo_paciente: 'P',
			id: exame.id_paciente
		};

		setTituloAlerta(strings.atencao);
		setOpcoesAlerta([
			{
				title: strings.sim,
				onClick: () => {
					setCarregando(true);
					setTimeout(() => {
						setAlerta(false);
					}, 0);

					axios
						.put(`${UTILITARIOS_API_BASE_URL}/paciente`, payload, { headers: getHeaders() })
						.then((response) => {
							fecharModal();
							callbackPacienteInformado();
							setAlerta(false);
						})
						.catch((err) => {
							console.log(err);
							const { response } = err;
							let msg = strings.erroGenerico;
							if (response) {
								if (response.status === HttpStatus.BAD_REQUEST) {
									const { data } = response;
									let arrMensagem = [];
									data.errors.forEach((error) => {
										arrMensagem.push(`- ${error.message}`);
									});
									msg = arrMensagem.join('\n');
									setTituloAlerta(data.message);
								} else {
									setTituloAlerta(strings.erro);
								}
							} else {
								setTituloAlerta(strings.erro);
							}
							setTipoAlerta('error');
							setMensagemAlerta(msg);
							setAlerta(true);
							setOpcoesAlerta([
								{
									title: strings.ok,
									onClick: () => {
										setAlerta(false);
									}
								}
							]);
							setOnCloseAlerta(() => () => {
								setAlerta(false);
							});
						})
						.finally(() => {
							setSubmitting(false);
							setCarregando(false);
						});
				}
			},
			{
				title: strings.nao,
				onClick: () => {
					setAlerta(false);
					setSubmitting(false);
				}
			}
		]);
		setMensagemAlerta(strings.confirmacaoVinculoDados);
		setTipoAlerta('alert');
		setOnCloseAlerta(() => () => {
			setAlerta(false);
			setSubmitting(false);
		});
		setAlerta(true);
	};

	return (
		<SttModal
			title={`${modo === CONSTANTE_MODO_CPF_PACIENTE ? strings.necessidadeInformarCpfPaciente : strings.necessidadeInformarDataNascimentoPaciente} - Requisição  ${exame?.requisicao || exame?.numero_requisicao || ''}`}
			open={true}
			outModalClose={() => {
				fecharModal();
				callbackFecharModal();
			}}
			iconClose={() => {
				fecharModal();
				callbackFecharModal();
			}}
			maxWidth={modo === CONSTANTE_MODO_CPF_PACIENTE ? 'md' : 'sm'}
			fullWidth={true}
			children={
				<Formik
					initialValues={{
						cpf: '',
						cns: '',
						dataNascimento: exame?.data_nascimento_paciente || null
					}}
					innerRef={ref}
					validationSchema={schema}
					onSubmit={(dados, { setSubmitting }) => {
						submitForm(dados, { setSubmitting });
					}}
					enableReinitialize
				>
					{({ isSubmitting, handleSubmit, setSubmitting, values, errors, validateForm }) => {
						return (
							<form
								onSubmit={handleSubmit}
								noValidate
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										buscarPacientePorCpfCns(values, setSubmitting);
									}
								}}
							>
								<div className={classes.form}>
									<SttNotification
										severity="warning"
										className={classes.notification}
									>
										<div className={classes.notificationContent}>
											<span>
												{modo === CONSTANTE_MODO_CPF_PACIENTE
													? strings.descricaoNecessidadeInformarCpfPaciente
													: strings.descricaoNecessidadeInformarDataNascimentoPaciente}
											</span>
										</div>
									</SttNotification>
									{modo === CONSTANTE_MODO_CPF_PACIENTE ? (
										<>
											<SttGrid
												container
												spacing={3}
											>
												<SttGrid
													item
													xs={12}
													md={6}
												>
													<FastField name={'cpf'}>
														{({ meta, field }) => (
															<>
																<SttMaskedInput
																	{...field}
																	mask="cpf"
																	label={strings.cpf}
																	error={meta.error ? true : false}
																	helperText={meta.error ? meta.error : undefined}
																/>
															</>
														)}
													</FastField>
												</SttGrid>
												<SttGrid
													item
													xs={12}
													md={6}
												>
													<FastField name={'cns'}>
														{({ meta, field }) => (
															<>
																<SttMaskedInput
																	{...field}
																	mask="cns"
																	label={strings.cns}
																	error={meta.error ? true : false}
																	helperText={meta.error ? meta.error : undefined}
																/>
															</>
														)}
													</FastField>
												</SttGrid>
												<SttGrid
													item
													xs={12}
													md={3}
												>
													<div className={classes.buttonWrapper}>
														<SttButton
															variant="contained"
															color="primary"
															nomarginleft="true"
															onClick={() => handleValidateCpfCns(values, setSubmitting, validateForm)}
															disabled={isSubmitting || errors.cpf || errors.cns}
														>
															{strings.buscar}
														</SttButton>
													</div>
												</SttGrid>
											</SttGrid>
											<div className={classes.tableWrapper}>
												<SttTable stickyHeader>
													<SttTableHead>
														<SttTableRow>
															<SttHidden smDown>
																<SttTableCell width="40%">{strings.nome}</SttTableCell>
																<SttTableCell width="12%">{strings.cpf}</SttTableCell>
																<SttTableCell width="10%">{strings.cns}</SttTableCell>
																<SttTableCell width="20%">{strings.dataNascimento}</SttTableCell>
																<SttTableCell width="10%">{strings.origem}</SttTableCell>
																<SttTableCell width="8%">{strings.selecionar}</SttTableCell>
															</SttHidden>
															<SttHidden mdUp>
																<SttTableCell
																	width="100%"
																	colSpan="2"
																>
																	{strings.pacientes}
																</SttTableCell>
															</SttHidden>
														</SttTableRow>
													</SttTableHead>
													<SttTableBody>
														{listaPacientes.length === 0 ? (
															<SttTableRow key={-1}>
																<SttTableCell
																	colSpan={6}
																	align="center"
																>
																	{strings.nenhumResultado}
																</SttTableCell>
															</SttTableRow>
														) : (
															listaPacientes.map((row, index) => (
																<SttTableRow key={index}>
																	<SttHidden smDown>
																		<SttTableCell>{row.nome}</SttTableCell>
																		<SttTableCell style={{ whiteSpace: 'pre' }}>{row.cpf}</SttTableCell>
																		<SttTableCell>{row.cartao_sus}</SttTableCell>
																		<SttTableCell>
																			<Moment
																				add={{ hours: 12 }}
																				format="DD/MM/YYYY"
																			>
																				{row.data_nascimento}
																			</Moment>
																		</SttTableCell>
																		<SttTableCell>{row.origem}</SttTableCell>
																	</SttHidden>
																	<SttHidden mdUp>
																		<SttTableCell width="96%">
																			<SttText size="small">
																				<b>{strings.nome}</b> {row.nome}
																			</SttText>
																			<SttText size="small">
																				<b>{strings.cpf}</b> {row.cpf}
																			</SttText>
																			<SttText size="small">
																				<b>{strings.cns}</b> {row.cartao_sus}
																			</SttText>
																			<SttText size="small">
																				<b>{strings.dataNascimento}</b>{' '}
																				<Moment
																					add={{ hours: 12 }}
																					format="DD/MM/YYYY"
																				>
																					{row.data_nascimento}
																				</Moment>
																			</SttText>
																			<SttText size="small">
																				<b>{strings.origem}</b> {row.origem}
																			</SttText>
																		</SttTableCell>
																	</SttHidden>
																	<SttTableCell align="center">
																		<SttAddButton
																			id={`btn-selecionar-paciente-${index}`}
																			onClick={() => handleClickSelecionar(row, setSubmitting)}
																		/>
																	</SttTableCell>
																</SttTableRow>
															))
														)}
													</SttTableBody>
												</SttTable>
											</div>
										</>
									) : (
										<SttGrid
											container
											spacing={3}
										>
											<SttGrid
												item
												xs={12}
											>
												<Field name={'dataNascimento'}>
													{({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => (
														<SttDatePicker
															label={strings.dataNascimento}
															inputprops={{
																name: name
															}}
															disableFuture
															maxDate={new Date()}
															invalidDateMessage={strings.dataInvalida}
															maxDateMessage={strings.erroDataFutura}
															error={meta.touched && meta.error ? true : false}
															value={value}
															onBlur={() => {
																setFieldTouched('dataNascimento', true);
															}}
															helperText={meta.touched && meta.error ? meta.error : undefined}
															onChange={(date) => {
																setFieldValue('dataNascimento', date, true);
															}}
															onClose={() => setFieldTouched('dataNascimento', true)}
														/>
													)}
												</Field>
											</SttGrid>
										</SttGrid>
									)}
								</div>
								<SttGrid
									container
									spacing={2}
								>
									<SttGrid
										item
										xs={12}
									>
										{modo === CONSTANTE_MODO_DATA_NASCIMENTO_PACIENTE && (
											<SttButton
												type="submit"
												variant="contained"
												color="primary"
												nomarginleft="true"
												disabled={isSubmitting || errors.dataNascimento}
											>
												{strings.confirmar}
											</SttButton>
										)}
										<SttButton
											type="button"
											variant="outlined"
											color="primary"
											disabled={isSubmitting}
											nomarginleft={modo === CONSTANTE_MODO_CPF_PACIENTE}
											onClick={() => {
												fecharModal();
												callbackFecharModal();
											}}
										>
											{strings.informarOutroMomento}
										</SttButton>
									</SttGrid>
								</SttGrid>

								<SttLoading
									open={isSubmitting || carregando}
									text={strings.carregando}
								/>
								<SttAlerta
									open={alerta}
									title={tituloAlerta}
									message={mensagemAlerta}
									type={tipoAlerta}
									options={opcoesAlerta}
									onClose={onCloseAlerta}
								/>
							</form>
						);
					}}
				</Formik>
			}
		/>
	);
};

ModalComplementoPacienteExame.propTypes = {
	strings: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user
	};
};

export default connect(mapStateToProps)(ModalComplementoPacienteExame);
