import React, { useContext } from 'react';
import { Field } from 'formik';
import { SttDatePicker, SttTranslateHook } from '@stt-componentes/core';
import { DATA_EXAME_DE } from '../fieldNames';

const DataInicialExame = () => {
	const { strings } = useContext(SttTranslateHook.I18nContext);

	return (
		<Field name={DATA_EXAME_DE}>
			{({ field: { name, value }, form: { setFieldValue, setFieldError, setFieldTouched }, meta }) => {
				let erroAtual = meta.error;
				return (
					<SttDatePicker
						label={strings.dataInicialExame}
						inputProps={{
							name: name,
							inputMode: 'numeric'
						}}
						error={meta.touched && meta.error ? true : false}
						onError={(error) => {
							if (error && error !== erroAtual) {
								setFieldError(DATA_EXAME_DE, error);
							}
						}}
						value={value}
						onBlur={() => {
							setFieldTouched(DATA_EXAME_DE, true);
						}}
						helperText={meta.touched && meta.error ? meta.error : undefined}
						onChange={(date) => {
							if (!date) return;

							date.setHours(0);
							date.setMinutes(0);
							date.setSeconds(0);
							setFieldValue(DATA_EXAME_DE, date, true);
						}}
						onClose={() => setFieldTouched(DATA_EXAME_DE, true)}
					/>
				);
			}}
		</Field>
	);
};

export default DataInicialExame;
