import * as yup from 'yup';
import {
    DATA_EXAME_DE,
    DATA_EXAME_ATE,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DATA_EXAME_DE]: yup.date().typeError(strings.dataInvalida).max(new Date(), strings.erroDataFutura).nullable(),
        [DATA_EXAME_ATE]: yup.date().typeError(strings.dataInvalida).min(yup.ref(DATA_EXAME_DE), strings.erroDataMenorDataInicio).max(new Date(), strings.erroDataFutura).nullable(),
    });
}
