import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from 'react-router-dom';
import Pesquisa from './pesquisa';
import Utils from '../../utils';
import {
    SttLoading,
    SttTranslateHook,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [gerandoPdf, setGerandoPdf] = useState(false);

    const imprimir = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirFn(exame, () => setGerandoPdf(false), false);
    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirProtocoloFn(exame, () => setGerandoPdf(false));
    }

    const imprimirMultiplosProtocolos = (exames) => {
        setGerandoPdf(true);
        Utils.imprimirMultiplosProtocolosFn(exames, () => setGerandoPdf(false));
    }

    return (
        <>
            <Switch>
                <Route path="/" children={<Pesquisa {...props} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirMultiplosProtocolosFn={imprimirMultiplosProtocolos} />} />
            </Switch>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );

}

export default Aba;