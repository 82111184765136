import Cookies from "js-cookie";
import { PAGINACAO, ORIGEM, SITUACAO_LAUDO } from '../constantes';

export const estadoInicialForm = (config) => {

    let dataDe = new Date();
    dataDe.setDate(dataDe.getDate() - 15);

    return {
        uf: null,
        municipio: null,
        instituicao: null,
        categoria: null,
        modalidade: null,
        'funcionario-envio': '',
        requisicao: '',
        paciente: '',
        'cns-paciente': '',
        'cpf-paciente': '',
        'data-exame-de': dataDe,
        'data-exame-ate': null,
        'situacao-laudo': [SITUACAO_LAUDO.SEM_LAUDO],
        situacao: ['V'],
        origem: [ORIGEM.AGENDADOS],
        protocolo: '',
        'descricao-exame': '',
        'tipo-busca-descricao': 'T',
        page: config.pagination.start,
        count: Cookies.get(PAGINACAO.REGISTROS_PAGINA) || config.pagination.count
    }
}